import { MutationTree } from 'vuex';
import { CommunitySessionsState, CommunitySessionsMutations } from './types';
import { ICommunitySession } from '@mentessa/types';

export const mutations: MutationTree<CommunitySessionsState> = {
  [CommunitySessionsMutations.SetCreateCommunitySessionVisible](state: CommunitySessionsState, visible: boolean) {
    state.createCommunitySessionVisible = visible;
  },
  [CommunitySessionsMutations.SetNewCommunitySessionId](state: CommunitySessionsState, id: number) {
    state.newSessionId = id;
  },
  [CommunitySessionsMutations.SetCommunitySessionForUser](
    state: CommunitySessionsState,
    sessions: ICommunitySession[],
  ) {
    state.sessionsCreatedByUser = sessions;
  },
};
