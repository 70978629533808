"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppointmentStatus = exports.AppointmentFeedback = void 0;
var AppointmentFeedback;
(function (AppointmentFeedback) {
    AppointmentFeedback["Positive"] = "positive";
    AppointmentFeedback["Negative"] = "negative";
})(AppointmentFeedback = exports.AppointmentFeedback || (exports.AppointmentFeedback = {}));
var AppointmentStatus;
(function (AppointmentStatus) {
    AppointmentStatus["Pending"] = "pending";
    AppointmentStatus["Confirmed"] = "confirmed";
    AppointmentStatus["Canceled"] = "canceled";
    AppointmentStatus["Finished"] = "finished";
})(AppointmentStatus = exports.AppointmentStatus || (exports.AppointmentStatus = {}));
