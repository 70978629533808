<template>
  <v-card class="community-session-card" @click="onCardClick(groupSession)">
    <div
      class="session-banner d-flex flex-column gap-1"
      v-bind:style="{ backgroundImage: 'url(' + groupSession.bannerImage + ')' }"
    >
      <div class="d-flex flex-row justify-space-between">
        <span class="align-self-center pt-1">{{ formattedDurarion }}</span>
        <v-btn class="starting-soon-indicator" color="#44dd54" style="padding: 10px">{{
          $t('session.starting_soon')
        }}</v-btn>
      </div>
      <h2>{{ groupSession.title }}</h2>
    </div>
    <div class="session-description d-flex flex-column justify-space-between">
      <p v-snip="{ lines: 3 }">{{ groupSession.description }}</p>
      <m-user-preview :user="groupSession.createdBy" />
    </div>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import { ICommunitySession } from '@mentessa/types';
import MUserPreview from '@/components/MUserPreview';
export default Vue.extend({
  name: 'm-community-session-card',
  components: { MUserPreview },
  props: {
    groupSession: Object as PropType<ICommunitySession>,
    onCardClick: Function,
  },
  computed: {
    ...mapGetters('ui', {
      formatInUserTimeZone: 'formatInUserTimeZone',
    }),
    formattedDurarion() {
      const formattedStart = this.formatInUserTimeZone(this.groupSession.startTime, 'MMM d, H:mm');
      return `${formattedStart}`;
    },
  },
});
</script>

<style lang="scss" scoped>
.v-card--link:before {
  background: none;
}

h2 {
  font-size: 20px;
}

.community-session-card {
  height: 280px;
  width: 360px;
}

.session-banner {
  height: 120px;
  padding: 16px;
}

.session-description {
  height: 160px;
  padding: 24px 16px 24px 16px;
}

.starting-soon-indicator {
  color: #ffffff;
  padding: 10px;
}
</style>
