"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MentoringSessionStatus = void 0;
var MentoringSessionStatus;
(function (MentoringSessionStatus) {
    MentoringSessionStatus["Progress"] = "progress";
    MentoringSessionStatus["Paused"] = "paused";
    MentoringSessionStatus["Finished"] = "finished";
    MentoringSessionStatus["Canceled"] = "canceled";
})(MentoringSessionStatus = exports.MentoringSessionStatus || (exports.MentoringSessionStatus = {}));
