<template>
  <m-side-panel-dialog :close="close" :value="true">
    <m-user-details-carousel @onUserChanged="loadUser" />
  </m-side-panel-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { RouteName } from '@/router/types';
import { MUserDetailsCarousel } from '@/components/Dialogs/MUserDetailsDialog/MUserDetailsCarousel';
import { LoadUserDetails } from '@/store/users';
import { MSidePanelDialog } from '@/components/Dialogs/MSidePanelDialog';

export default Vue.extend({
  name: 'm-user-details-dialog',
  components: {
    MUserDetailsCarousel,
    MSidePanelDialog,
  },
  props: {
    userId: { type: Number },
  },
  data: () => ({
    user: undefined,
  }),
  async mounted() {
    await this.loadUser();
  },
  methods: {
    async loadUser() {
      const user = await this.$store.dispatch(new LoadUserDetails(this.userId));
      if (user) {
        this.user = user;
      } else {
        await this.close();
      }
    },
    async close() {
      await this.$router.push({ name: RouteName.Community });
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
</style>
