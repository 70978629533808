<template>
  <v-btn outlined color="button" @click="copyToClipboard">
    <div class="d-flex flex-column">
      <div class="d-flex align-center justify-end flex-row gap-2" :class="topLabelClasses">
        <span>{{ $t('community_session_details_dialog.share') }}</span>
        <v-icon>mdi-content-copy</v-icon>
      </div>
      <div v-if="bottomLevelClasses" :class="bottomLevelClasses">Copied</div>
    </div>
  </v-btn>
</template>
<script>
import Vue from 'vue';
import { copy } from '@/utils/clipboard';

// Animation length + extra 200ms to prevent flickering in the end of the animation
const ANIMATE_TIMEOUT = 2000 + 200;

export default Vue.extend({
  name: 'm-copy-link-button',
  props: {
    label: { type: String, default: 'Copy link' },
    linkToCopy: { type: String },
  },
  data: () => ({
    animate: false,
    topLabelClasses: '',
    bottomLevelClasses: '',
  }),
  computed: {},
  methods: {
    async copyToClipboard() {
      this.topLabelClasses = 'slide-out-top';
      this.bottomLevelClasses = 'slide-in-bottom';
      await copy(window.location.href);
      setTimeout(() => {
        this.topLabelClasses = 'slide-in-top';
        this.bottomLevelClasses = 'slide-out-bottom';
      }, ANIMATE_TIMEOUT);
    },
  },
});
</script>
<style>
.v-btn {
  overflow: hidden;
}

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  margin-bottom: 26px;
}

.slide-in-top {
  -webkit-animation: slide-in-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  margin-top: 42px;
}

.slide-out-top {
  -webkit-animation: slide-out-top 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: slide-out-top 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.slide-out-bottom {
  -webkit-animation: slide-out-bottom 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: slide-out-bottom 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  margin-bottom: 26px;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
}

@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
}

@-webkit-keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}

@keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
