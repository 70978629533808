<template>
  <div>
    <m-dialog :slim="false" :persistent="true" :fullscreen="false" :value="show" :useDefaultHeight="false">
      <div class="d-flex success-dialog align-center justify-center flex-column gap-5">
        <h1>{{ $t('create_community_session.session_created_succesfully') }}</h1>
        <h2>{{ $t('create_community_session.share_your_link') }}</h2>
        <h2>
          <a class="share-link" @click="copyToClipboard">{{ sessionLink }} <v-icon>mdi-content-copy</v-icon></a>
        </h2>
        <v-btn color="button" @click="closeCreateCommunitySessionDialog()" x-large>{{
          $t('create_community_session.close')
        }}</v-btn>
      </div>
    </m-dialog>
    <m-confetti :startConfetti="show" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import { CloseCommunitySessionCreatedSuccessfull, CommunitySessionsState } from '@/store/communitySessions';
import MDialog from '@/components/Dialogs/MDialog';
import { copy } from '@/utils/clipboard';
import { ShowNotification } from '@/store/notifications';
import i18n from '@/plugins/i18n';
import MConfetti from '@/components/MConfetti';

export default Vue.extend({
  name: 'm-community-session-succes-dialog',
  components: {
    MDialog,
    MConfetti,
  },
  computed: {
    ...mapState<CommunitySessionsState>('communitySessions', {
      sessionId: (state: CommunitySessionsState) => state.newSessionId,
    }),
    show() {
      return this.sessionId !== undefined;
    },
    sessionLink() {
      return `${window.origin}/community/sessions?session=${this.sessionId}`;
    },
  },
  methods: {
    async copyToClipboard() {
      await copy(this.sessionLink);
      await this.$store.dispatch(new ShowNotification(i18n.t('notifications.ok').toString()));
    },
    closeCreateCommunitySessionDialog() {
      this.$store.dispatch(new CloseCommunitySessionCreatedSuccessfull());
    },
  },
});
</script>

<style lang="scss" scoped>
.smaller-dialog {
  .m-dialog-container {
    height: 270px;
  }
}

.success-dialog {
  height: 100%;
  button {
    width: 240px;
    font-size: 16px;
  }
}

.share-link {
  color: #666666;
}
</style>
