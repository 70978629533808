<template>
  <m-autocomplete
    v-model="localValue"
    :items="sortedLanguages"
    :search-input.sync="search"
    item-text="name"
    item-value="code"
    sentry-tag="language"
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
  >
    <template v-slot:selection="data">
      <v-chip close close-icon="close" @click:close="deleteChip(data)">
        {{ data.item.name }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      {{ data.item.name }}
    </template>
  </m-autocomplete>
</template>

<script lang="ts">
import MAutocomplete from '@/components/Inputs/MAutocomplete/MAutocomplete.vue';
import { mapGetters, mapState } from 'vuex';
import { Language, UtilsState } from '@/store/utils';

const defaultLangPriority = ['de', 'en', 'fr', 'it'].reverse();

export default MAutocomplete.extend({
  name: 'm-language',
  components: {
    MAutocomplete,
  },
  props: {
    multiple: { type: Boolean, default: true },
    noDefaultPriority: { type: Boolean, default: false },
    langPriority: { type: Array<string>, default: () => [] },
  },
  data: () => ({
    search: '',
  }),
  computed: {
    ...mapGetters('utils', {
      nameForLang: 'nameForLang',
    }),
    ...mapState<UtilsState>('utils', {
      languages: (state: UtilsState) => Object.entries(state.languages).map((x) => ({ code: x[0], name: x[1] })),
    }),
    mergedPriority() {
      const result = [...this.langPriority];
      if (!this.noDefaultPriority) {
        result.push(...defaultLangPriority);
      }

      return [...new Set(result)].sort();
    },
    sortedLanguages() {
      return this.languages.sort((l, r) => this.mergedPriority.indexOf(r.code) - this.mergedPriority.indexOf(l.code));
    },
    localValue: {
      get() {
        if (Array.isArray(this.value)) {
          return this.value.map((value) => ({ code: value, name: this.nameForLang(value) }));
        } else {
          return { code: this.value, name: this.nameForLang(this.value) };
        }
      },
      set(value: Language | Array<Language>) {
        if (Array.isArray(value)) {
          this.$emit(
            'input',
            value.map((item) => item.code),
          );
        } else {
          this.$emit('input', value.code);
        }
        this.search = '';
      },
    },
  },
  methods: {
    deleteChip(chip) {
      this.$emit('input', [...this.value.slice(0, chip.index), ...this.value.slice(chip.index + 1)]);
    },
  },
});
</script>
