<template>
  <div class="picker-field">
    <v-menu v-model="menu" :close-on-content-click="false" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <m-text-field v-model="valueModel" readonly outlined v-bind="{ ...$props, ...$attrs, ...attrs }" v-on="on" />
      </template>
      <v-time-picker ref="timeRef" v-model="valueModel" color="primary" format="24hr" @input="menu = false" />
    </v-menu>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { UIState } from '@/store/ui';
import { mapState } from 'vuex';
import MTextField from '../MTextField';

export default Vue.extend({
  name: 'm-time-picker',
  components: { MTextField },
  props: {
    value: [String, Number],
    label: [String, Object /*as PropType<TranslateResult>*/],
    isRequired: { type: Boolean, default: false },
    requiredSuffix: { type: String, default: ' *' },
  },
  data: function () {
    return {
      menu: false,
    };
  },
  computed: {
    ...mapState<UIState>('ui', {
      locale: (state: UIState) => state.translations.dateLocale.code,
    }),
    labelWithRequired() {
      return this.isRequired ? `${this.label ?? ''}${this.requiredSuffix}` : this.label;
    },
    valueModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('onValueChange', newValue);
      },
    },
  },
  watch: {},
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
.picker-field {
  max-width: 110px;
}

.custom-time-picker .v-time-picker-title {
  pointer-events: none;
}
</style>
