"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuickFilterValueSource = void 0;
var QuickFilterValueSource;
(function (QuickFilterValueSource) {
    QuickFilterValueSource["Location"] = "location";
    QuickFilterValueSource["Interests"] = "interests";
    QuickFilterValueSource["Expertise"] = "expertise";
    QuickFilterValueSource["TrandingInterest"] = "tranding_interests";
    QuickFilterValueSource["TrandingExpertise"] = "tranding_expertise";
})(QuickFilterValueSource = exports.QuickFilterValueSource || (exports.QuickFilterValueSource = {}));
