"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncDirection = exports.SyncField = exports.QuestionType = void 0;
var QuestionType;
(function (QuestionType) {
    QuestionType["FreeText"] = "free_text";
    QuestionType["FreeTextLine"] = "free_text_line";
    QuestionType["FreeTags"] = "free_tags";
    QuestionType["FreeSkillsTags"] = "free_skills_tags";
    QuestionType["Scale"] = "scale";
    QuestionType["ScaleMultiple"] = "scale_multiple";
    QuestionType["ChipGroup"] = "chip_group";
    QuestionType["Schedule"] = "schedule";
    QuestionType["Weekday"] = "weekday";
    QuestionType["WeekdayExtended"] = "weekday_extended";
    QuestionType["RatingWithText"] = "rating_with_text";
    QuestionType["Rating"] = "rating";
    QuestionType["Submit"] = "submit";
    QuestionType["Guidelines"] = "guidelines";
    QuestionType["SelectOne"] = "select_one";
    QuestionType["YesNo"] = "yes_no";
    QuestionType["Number"] = "number";
})(QuestionType = exports.QuestionType || (exports.QuestionType = {}));
var SyncField;
(function (SyncField) {
    SyncField["About"] = "about";
    SyncField["Company"] = "company";
    SyncField["Custom"] = "custom";
    SyncField["Expertise"] = "expertise";
    SyncField["Interests"] = "interests";
    SyncField["Position"] = "position";
    SyncField["TimeSlots"] = "time_slots";
    SyncField["MeetingLocation"] = "meeting_location";
    SyncField["Gender"] = "gender";
})(SyncField = exports.SyncField || (exports.SyncField = {}));
var SyncDirection;
(function (SyncDirection) {
    SyncDirection["Push"] = "push";
    SyncDirection["Pull"] = "pull";
    SyncDirection["Sync"] = "sync";
})(SyncDirection = exports.SyncDirection || (exports.SyncDirection = {}));
