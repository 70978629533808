<template>
  <m-side-panel-dialog :close="close" :value="open" :backgroundImageUrl="session?.bannerImage">
    <v-card v-if="session" class="session-details-card d-flex flex-column gap-10">
      <div class="d-flex flex-row justify-space-between align-center mb-8">
        <div class="d-flex flex-column">
          <h1>{{ session.title }}</h1>
          <h3>{{ formattedDurarion }}</h3>
        </div>
        <div class="d-flex flex-column gap-2">
          <div class="join-container d-flex flex-column gap-1" v-if="isSubscribedToSession">
            <v-btn color="button" @click="joinSession">{{ $t('community_session_details_dialog.join') }}</v-btn>
            <div class="d-flex flex-row gap-1 cant-make-it">
              <span>{{ $t('community_session_details_dialog.cant_make_it') }}</span>
              <a @click="openCancelDialog">{{ $t('community_session_details_dialog.cancel') }}</a>
            </div>
          </div>
          <v-btn v-if="showSubscribe" color="button" @click="subscribeToSession">{{
            $t('community_session_details_dialog.subscribe')
          }}</v-btn>
          <v-btn v-if="isMySession" color="button" @click="joinSession">{{
            $t('community_session_details_dialog.start')
          }}</v-btn>
          <m-copy-link-button :label="$t('community_session_details_dialog.share')" :linkToCopy="shareLink" />
        </div>
      </div>
      <div class="d-flex flex-column gap-8">
        <div class="d-flex flex-column gap-8">
          <div class="d-flex flex-row justify-space-between">
            <m-user-preview :user="session.createdBy" big bold job />
            <m-attendee-summary :attendees="session.participants" :maxAttendees="session.maximumAttendees" />
          </div>
        </div>
        <h3 v-if="session.language">
          {{ $t('community_session_details_dialog.language') }}: {{ nameForLang(session.language) }}
        </h3>
        <p>{{ session.description }}</p>
      </div>
      <div class="d-flex flex-column gap-2">
        <h3 class="align-content-center text-no-wrap">{{ $t('community_session_details_dialog.topics') }}</h3>
        <v-chip-group class="d-flex" column multiple>
          <v-chip v-for="(tag, index) in session.topics" :key="index">{{ tag.name }}</v-chip>
        </v-chip-group>
      </div>
      <div class="d-flex flex-column gap-2">
        <h3 class="align-content-center">{{ $t('community_session_details_dialog.roles') }}</h3>
        <v-chip-group class="d-flex flex-row gap-2">
          <v-chip v-for="(tag, index) in session.attributes.roles" :key="index">{{ tag }}</v-chip>
        </v-chip-group>
        <m-dialog :value="showSubscribedDialog" :useDefaultHeight="false" @input="closeSuccess()">
          <div class="d-flex align-center justify-center flex-column gap-6">
            <h1 class="align-self-start">{{ $t('community_session_details_dialog.subscribed_successfully') }}</h1>
            <v-btn class="dialogButton" color="button" @click="closeSuccess()" x-large>{{
              $t('community_session_details_dialog.subscribed_successfully_ok')
            }}</v-btn>
          </div>
        </m-dialog>
        <m-dialog :value="showCancelDialog" :useDefaultHeight="false" @input="closeCancelDialog()">
          <div class="d-flex align-center justify-center flex-column gap-6">
            <h1 class="align-self-start">{{ $t('community_session_details_dialog.do_you_want_to_leave') }}</h1>
            <div class="button-container d-flex flex-row justify-space-between gap-2">
              <v-btn class="dialogButton" color="button" @click="unsubscribeFromSession()" x-large>{{
                $t('community_session_details_dialog.leave')
              }}</v-btn>
              <v-btn class="dialogButton" outlined color="button" @click="closeCancelDialog()" x-large>{{
                $t('community_session_details_dialog.back')
              }}</v-btn>
            </div>
          </div>
        </m-dialog>
      </div>
    </v-card>
    <v-skeleton-loader v-if="session === undefined" height="100%" type="card" width="100%" />
  </m-side-panel-dialog>
</template>
<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { IUser } from '@mentessa/types';
import { MSidePanelDialog } from '@/components/Dialogs/MSidePanelDialog';
import MUserPreview from '@/components/MUserPreview';
import MCopyLinkButton from '@/components/MCopyLinkButton';
import { TenantState } from '@/store/tenant';
import {
  LoadCommunintySession,
  SubscribeToCommunitySession,
  UnsubscribeToCommunitySession,
} from '@/store/communitySessions';
import MDialog from '@/components/Dialogs/MDialog';
import { UsersState } from '@/store/users';
import { isWithinInterval, interval } from 'date-fns';
import { MAttendeeSummary } from '@/components/MAttendeeSummary';

export default Vue.extend({
  name: 'm-community-session-dialog',
  components: {
    MSidePanelDialog,
    MUserPreview,
    MDialog,
    MCopyLinkButton,
    MAttendeeSummary,
  },
  props: {
    open: { type: Boolean, default: false },
    sessionId: Number,
    onClose: Function,
  },
  data: () => ({
    session: undefined,
    showSubscribedDialog: false,
    showCancelDialog: false,
  }),
  computed: {
    ...mapGetters('ui', {
      formatInUserTimeZone: 'formatInUserTimeZone',
    }),
    ...mapGetters('utils', {
      nameForLang: 'nameForLang',
    }),
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    ...mapState<UsersState>('users', {
      me: (state: UsersState): IUser => state.me,
    }),
    formattedStart() {
      return this.formatInUserTimeZone(this.session.startTime, 'MMM d, H:mm');
    },
    formattedEnd() {
      return this.formatInUserTimeZone(this.session.endTime, 'H:mm (O)');
    },
    formattedDurarion() {
      return `${this.formattedStart} - ${this.formattedEnd}`;
    },
    isSubscribedToSession() {
      return this.session?.participants.findIndex((participant) => participant.id === this.me.id) !== -1;
    },
    isMySession() {
      return this.session?.createdBy.id === this.me.id;
    },
    showSubscribe() {
      return !this.isSubscribedToSession && !this.isMySession;
    },
    isInProgress() {
      return this.session && isWithinInterval(new Date(), interval(this.session.startTime, this.session.endTime));
    },
    shareLink() {
      return window.location.href;
    },
  },
  methods: {
    subscribeToSession() {
      this.$store.dispatch(new SubscribeToCommunitySession(this.tenant, this.sessionId)).then((newSession) => {
        this.session = newSession;
        this.showSubscribedDialog = true;
      });
    },
    unsubscribeFromSession() {
      this.$store.dispatch(new UnsubscribeToCommunitySession(this.tenant, this.sessionId)).then((newSession) => {
        this.session = newSession;
        this.closeCancelDialog();
      });
    },
    joinSession() {
      window.open(this.session.meetingUrl, '_blank');
    },
    close() {
      this.session = undefined;
      this.onClose();
    },
    closeSuccess() {
      this.showSubscribedDialog = false;
    },
    closeCancelDialog() {
      this.showCancelDialog = false;
    },
    openCancelDialog() {
      this.showCancelDialog = true;
    },
  },
  async mounted() {
    if (this.sessionId) {
      const sessionsData = await this.$store.dispatch(new LoadCommunintySession(this.tenant, this.sessionId));
      this.session = sessionsData;
    }
  },
  watch: {
    async open(isOpen) {
      if (isOpen && this.sessionId) {
        const sessionsData = await this.$store.dispatch(new LoadCommunintySession(this.tenant, this.sessionId));
        this.session = sessionsData;
      }
    },
  },
});
</script>
<style lang="scss">
.session-details-card {
  margin-top: -14px;
  padding: 16px 16px 2px 16px;
  border-radius: 18px 18px 0 0 !important;

  .v-btn {
    width: 217px;
    height: 44px !important;
  }

  .join-container {
    .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
      background-color: #666666 !important;
      span {
        color: #ffffff;
      }
    }

    .cant-make-it {
      color: #666666;
    }

    a {
      color: #ea473b;
      text-decoration: underline;
    }
  }
}

.button-container {
  width: 100%;
}

.dialogButton {
  width: 48%;
}
</style>
