"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RitualSchedulePeriodicFrequency = exports.RitualScheduleType = void 0;
var RitualScheduleType;
(function (RitualScheduleType) {
    RitualScheduleType["Once"] = "once";
    RitualScheduleType["Periodic"] = "periodic";
})(RitualScheduleType = exports.RitualScheduleType || (exports.RitualScheduleType = {}));
var RitualSchedulePeriodicFrequency;
(function (RitualSchedulePeriodicFrequency) {
    RitualSchedulePeriodicFrequency["Custom"] = "custom";
    RitualSchedulePeriodicFrequency["Daily"] = "daily";
    RitualSchedulePeriodicFrequency["Weekly"] = "weekly";
    RitualSchedulePeriodicFrequency["BiWeekly"] = "biweekly";
    RitualSchedulePeriodicFrequency["Monthly"] = "monthly";
})(RitualSchedulePeriodicFrequency = exports.RitualSchedulePeriodicFrequency || (exports.RitualSchedulePeriodicFrequency = {}));
