import Vue from 'vue';
import Vuex from 'vuex';

import { RootState } from '@/store/types';
import { actions } from './actions';
import { mutations } from './mutations';

import tenantModule from '@/store/tenant';
import usersModule from '@/store/users';
import utilsModule from '@/store/utils';
import uiModule from '@/store/ui';
import messagesModule from '@/store/messages';
import searchModule from '@/store/search';
import matchingModule from '@/store/matching';
import invitesModule from '@/store/invites';
import ritualsModule from '@/store/rituals';
import statsModule from '@/store/stats';
import notificationsModule from '@/store/notifications';
import tokensModule from '@/store/tokens';
import videosModule from '@/store/videos';
import resourcesModule from '@/store/resources';
import newsModule from '@/store/news';
import emailsModule from '@/store/emails';
import mentoringModule from '@/store/mentoring';
import dashboardsModule from '@/store/dashboards';
import npsModule from '@/store/nps';
import contentModule from '@/store/content';
import communityRulesModule from './communityRules';
import ritualTemplatesModule from '@/store/ritualTemplates';
import mobileModule from '@/store/mobile';
import communitySessionsModule from '@/store/communitySessions';

Vue.use(Vuex);

export const initialState: RootState = {
  initialized: false,
};

export default new Vuex.Store<RootState>({
  state: initialState,
  actions,
  mutations,
  modules: {
    tenant: tenantModule,
    users: usersModule,
    utils: utilsModule,
    ui: uiModule,
    messages: messagesModule,
    search: searchModule,
    matching: matchingModule,
    invites: invitesModule,
    rituals: ritualsModule,
    stats: statsModule,
    notifications: notificationsModule,
    tokens: tokensModule,
    videos: videosModule,
    news: newsModule,
    emails: emailsModule,
    mentoring: mentoringModule,
    resources: resourcesModule,
    content: contentModule,
    dashboards: dashboardsModule,
    nps: npsModule,
    communityRules: communityRulesModule,
    ritualTemplates: ritualTemplatesModule,
    mobile: mobileModule,
    communitySessions: communitySessionsModule,
  },
  strict: process.env.NODE_ENV !== 'production',
});
