<template>
  <v-dialog
    :fullscreen="fullscreen || $vuetify.breakpoint.smAndDown"
    :persistent="persistent"
    :value="show"
    width="800px"
  >
    <div :class="containerClasses" class="m-dialog-container">
      <slot></slot>
      <v-btn v-if="!persistent" :class="closeBtnClasses" absolute color="button" icon right top>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'm-dialog',
  props: {
    value: { type: Boolean, default: true },
    persistent: { type: Boolean, default: false },
    slim: { type: Boolean, default: false },
    fullscreen: { type: Boolean, default: false },
    useDefaultHeight: { type: Boolean, default: true },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
    containerClasses() {
      return {
        'pa-2': !this.slim,
        'pa-sm-8': !this.slim,
        'force-fill-height': this.fullscreen,
        'default-height': this.useDefaultHeight,
      };
    },
    closeBtnClasses() {
      return {
        'mt-n3': !this.slim,
        'mr-n3': !this.slim,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.default-height {
  height: 512px;
}

.m-dialog-container {
  position: relative;
  background-color: var(--v-white-base);
  height: 550px;
  overflow-y: auto;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    height: 100%;
  }
}
</style>
