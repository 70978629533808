<template>
  <div class="p-1 d-flex align-center gap-4 m-benefits-card">
    <v-img :max-height="80" :max-width="80" :src="image" />
    <div class="m-benefits-card_content">
      <h3 class="font-size-20">{{ title }}</h3>
      <p class="font-size-16">{{ subtitle }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'm-community-session-benifit-card',
  props: {
    image: String,
    title: String,
    subtitle: String,
  },
});
</script>

<style lang="scss" scoped>
@mixin user-icon-field {
  margin-bottom: 4px;
  display: flex;

  &:before {
    margin-right: 5px;
    position: relative;
    top: 3px;
  }
}

.m-benefits-card {
  height: 96px;

  .m-benefits-card_content {
    display: flex;
    justify-content: center;
    flex-direction: column;

    h3 {
      color: var(--v-primary-base);
    }

    p {
      font-weight: 400;
    }
  }
}
</style>
