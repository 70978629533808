import { UsersState } from '@/store/users';
import { TenantState } from '@/store/tenant';
import { UtilsState } from '@/store/utils';
import { MessagesState } from '@/store/messages';
import { SearchState } from '@/store/search';
import { MatchingState } from '@/store/matching';
import { InvitesState } from '@/store/invites';
import { RitualsState } from '@/store/rituals';
import { VideosState } from '@/store/videos';
import { ResourcesState } from '@/store/resources';
import { UIState } from '@/store/ui';
import { StatsState } from '@/store/stats';
import { NotificationsState } from '@/store/notifications';
import { TokensState } from '@/store/tokens';
import { MentoringState } from '@/store/mentoring';
import { DashboardsState } from '@/store/dashboards';
import { NpsState } from '@/store/nps';
import { CommunityRulesState } from './communityRules';
import { RitualTemplatesState } from '@/store/ritualTemplates';
import { MobileState } from '@/store/mobile';
import { CommunitySessionsState } from './communitySessions/types';

export interface RootState {
  initialized: boolean;
  users?: UsersState;
  tenant?: TenantState;
  utils?: UtilsState;
  ui?: UIState;
  notifications?: NotificationsState;
  messages?: MessagesState;
  search?: SearchState;
  matching?: MatchingState;
  invites?: InvitesState;
  rituals?: RitualsState;
  stats?: StatsState;
  tokens?: TokensState;
  videos?: VideosState;
  resources?: ResourcesState;
  mentoring?: MentoringState;
  dashboards?: DashboardsState;
  nps?: NpsState;
  communityRules?: CommunityRulesState;
  communitySessions?: CommunitySessionsState;
  ritualTemplates?: RitualTemplatesState;
  mobile?: MobileState;
}

export interface Action {
  readonly type: string;
}

export enum StoreDataState {
  Unknown = 'unknown',
  Loading = 'loading',
  Loaded = 'loaded',
  Error = 'error',
}

export interface StoreData<T> {
  data: T;
  state: StoreDataState;
}

export enum RootActions {
  Initialize = '[Root] Initialize',
}

export enum RootMutations {
  Initialize = '[Root] Initialize',
  Logout = '[Root] Logout',
}

export class Initialize implements Action {
  type = `${RootActions.Initialize}`;

  constructor(
    public readonly loadData: boolean,
    public readonly initSession: boolean = false,
    public readonly loadNps: boolean = false,
  ) {}
}
