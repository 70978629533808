<template>
  <v-app-bar app class="m-app-bar-header" color="#fff" dense fixed sentry-tag="app-bar">
    <div class="d-flex fill-width align-center mt-3">
      <m-app-bar-menu-tenant />
      <div class="d-flex align-center ml-auto">
        <v-btn
          class="cs-button"
          color="primary"
          @click="toggleCreateCommunitySessionVisible"
          small
          v-if="isCommunitySessionsEnabled"
        >
          + {{ $t('appBar.communitySession') }}
        </v-btn>
        <v-tooltip v-if="showCommunityRules" bottom>
          <template #activator="{ on }">
            <v-img class="icon-rules" src="@/assets/sidebar/book.svg" @click="toggleCommunityRulesVisible" v-on="on" />
          </template>
          <span>{{ $t('appBar.communityRules') }}</span>
        </v-tooltip>
        <router-link v-if="isMessagesAvailable" :to="inboxRoute" sentry-tag="app-bar.link.messages">
          <v-icon class="ma-3" color="primary"> mdi-message-text</v-icon>
        </router-link>
        <m-app-bar-menu-profile />
      </div>
    </div>
  </v-app-bar>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { UserEntity } from '@mentessa/types';
import MAppBarMenuProfile from './menu/AppBarMenuProfile.vue';
import { RouteName } from '@/router/types';
import { ChangeCommunityRulesVisible, UIState } from '@/store/ui';
import { ChangeCreateCommunitySessionVisible, CommunitySessionsState } from '@/store/communitySessions';
import { TenantState } from '@/store/tenant';
import MAppBarMenuTenant from '@/components/MAppBar/menu/AppBarMenuTenant.vue';

export default Vue.extend({
  name: 'm-app-bar',
  components: {
    MAppBarMenuTenant,
    MAppBarMenuProfile,
  },
  props: {
    user: Object as PropType<UserEntity>,
  },
  data: () => ({
    inboxRoute: { name: RouteName.Inbox },
  }),
  computed: {
    ...mapGetters('tenant', {
      isMessagesAvailable: 'isMessagesAvailable',
      isCommunityRulesAvailable: 'isCommunityRulesAvailable',
      isCommunityRulesEnabled: 'isCommunityRulesEnabled',
      isCommunitySessionsEnabled: 'isCommunitySessionsEnabled',
      isInvitesAvailable: 'isInvitesAvailable',
    }),
    ...mapState<UIState>('ui', {
      communityRulesVisible: (state: UIState) => state.communityRulesVisible,
    }),
    ...mapState<UIState>('communitySessions', {
      createCommunitySessionVisible: (state: CommunitySessionsState) => state.createCommunitySessionVisible,
    }),
    ...mapState<TenantState>('tenant', {
      tenantName: (state: TenantState) => state.tenant?.attributes?.theme?.title ?? '',
    }),
    showCommunityRules() {
      return this.isCommunityRulesAvailable || (this.isInvitesAvailable && this.isCommunityRulesEnabled);
    },
  },
  methods: {
    toggleCommunityRulesVisible() {
      this.$store.dispatch(new ChangeCommunityRulesVisible(!this.communityRulesVisible));
    },
    toggleCreateCommunitySessionVisible() {
      this.$store.dispatch(new ChangeCreateCommunitySessionVisible(!this.createCommunitySessionVisible));
    },
  },
});
</script>

<style lang="scss" scoped>
.m-app-bar-header {
  z-index: 1;
  min-height: 60px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.12) !important;

  :deep(.v-tab--active) span {
    font-weight: 700;
  }
}

.cs-button {
  margin-right: 16px;
  font-size: 0.9rem;
}

a {
  text-decoration: none;
}

.icon-rules {
  cursor: pointer;
}

.icon-rules:active {
  background-color: var(--v-beige-base);
}
</style>
