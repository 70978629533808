"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommunitySessionStatus = void 0;
var CommunitySessionStatus;
(function (CommunitySessionStatus) {
    CommunitySessionStatus["Upcoming"] = "upcoming";
    CommunitySessionStatus["Live"] = "live";
    CommunitySessionStatus["Canceled"] = "canceled";
    CommunitySessionStatus["Finished"] = "finished";
})(CommunitySessionStatus = exports.CommunitySessionStatus || (exports.CommunitySessionStatus = {}));
