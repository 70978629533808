<template>
  <v-menu v-model="menu" :close-on-content-click="false" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <m-text-field v-model="valueModel" readonly outlined v-bind="{ ...$props, ...$attrs, ...attrs }" v-on="on" />
    </template>
    <v-date-picker v-model="valueModel" @input="menu = false"></v-date-picker>
  </v-menu>
</template>

<script lang="ts">
import Vue from 'vue';
import { UIState } from '@/store/ui';
import { mapState } from 'vuex';
import MTextField from '../MTextField';

export default Vue.extend({
  name: 'm-date-picker',
  components: { MTextField },
  props: {
    value: [String, Number],
    label: [String, Object /*as PropType<TranslateResult>*/],
    isRequired: { type: Boolean, default: false },
    requiredSuffix: { type: String, default: ' *' },
  },
  data: function () {
    return {
      menu: false,
    };
  },
  computed: {
    ...mapState<UIState>('ui', {
      locale: (state: UIState) => state.translations.dateLocale.code,
    }),
    labelWithRequired() {
      return this.isRequired ? `${this.label ?? ''}${this.requiredSuffix}` : this.label;
    },
    valueModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('onValueChange', newValue);
      },
    },
  },
  watch: {},
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
.picker-field {
  max-width: 110px;
}
</style>
