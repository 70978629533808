import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { mutations } from './mutations';
import { actions } from './actions';
import { CommunitySessionsState } from './types';

export * from './types';

export const initialState: CommunitySessionsState = {
  createCommunitySessionVisible: false,
  newSessionId: undefined,
  sessionsCreatedByUser: [],
};

export const uiModule: Module<CommunitySessionsState, RootState> = {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
};

export default uiModule;
