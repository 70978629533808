<template>
  <div>
    <m-dialog
      :slim="true"
      :persistent="true"
      :fullscreen="true"
      :value="show"
      sentry-tag="new-create-community-session-dialog"
    >
      <div class="section-group d-flex">
        <div class="form-section d-flex flex-row align-center justify-left">
          <validation-observer ref="observer" v-slot="{ invalid }">
            <div class="d-flex justify-left align-left flex-column">
              <template v-if="currentStep === 0">
                <div class="d-flex align-left flex-column gap-10">
                  <h1>
                    {{ $t('create_community_session.greeting') }} <span>{{ firstName }}</span
                    >!
                  </h1>
                  <h2>{{ $t('create_community_session.call_to_create') }}</h2>
                </div>
                <v-card class="mt-5 pa-2 d-flex align-center flex-column" width="233">
                  <m-icon :src="require('@/assets/communitySessions/camera-icon.svg')" :big="true" />
                  <v-card-title class="justify-center">
                    <p>{{ $t('create_community_session.ask_for_meeting_link') }}</p>
                  </v-card-title>
                  <v-card-text class="card-text"> {{ $t('create_community_session.link_examles') }} </v-card-text>
                </v-card>
                <m-text-field
                  v-model="url"
                  class="mt-4"
                  :placeholder="'http://zoom.com'"
                  validation-rules="required|url"
                />
              </template>
              <template v-if="currentStep === 1">
                <div class="d-flex align-left flex-column gap-5">
                  <h1>
                    <span>{{ $t('create_community_session.create_your_session') }} </span>
                  </h1>
                  <h2>{{ $t('create_community_session.enter_details') }}</h2>
                  <v-img :src="require('@/assets/communitySessions/step-one-indicator.svg')" :width="136" />
                </div>
                <div class="mt-5 d-flex flex-column gap-5">
                  <m-banner-image-picker v-model="image" />
                  <h3>{{ $t('create_community_session.session_title') }}</h3>
                  <m-text-field
                    v-model="title"
                    :placeholder="$t('create_community_session.session_title_placeholder')"
                    validation-rules="required|min:4|max:60"
                  />
                  <h3>{{ $t('create_community_session.session_description') }}</h3>
                  <m-text-area
                    v-model="description"
                    :placeholder="$t('create_community_session.session_description_placeholder')"
                    validation-rules="required|min:30|max:500"
                  />
                </div>
              </template>
              <template v-if="currentStep === 2">
                <div class="d-flex align-left flex-column gap-4">
                  <h1>
                    <span>{{ $t('create_community_session.create_your_session') }} </span>
                  </h1>
                  <h2>{{ $t('create_community_session.enter_schedule') }}</h2>
                  <v-img :src="require('@/assets/communitySessions/step-two-indicator.svg')" :width="136" />
                  <div class="d-flex flex-row gap-5">
                    <div class="d-flex flex-column gap-2">
                      <h3>{{ $t('create_community_session.date') }}</h3>
                      <m-date-picker :value="date" @onValueChange="changeDate" validation-rules="required" />
                    </div>
                    <div class="d-flex flex-column gap-2">
                      <h3>Time</h3>
                      <div class="d-flex flex-row gap-5">
                        <m-time-picker
                          :label="$t('create_community_session.fromTimeLabel')"
                          :value="fromTime"
                          @onValueChange="changeFromTime"
                          validation-rules="required"
                        />
                        <m-time-picker
                          :label="$t('create_community_session.toTimeLabel')"
                          :value="toTime"
                          @onValueChange="changeToTime"
                          validation-rules="required"
                        />
                        <m-time-zone-picker
                          v-model="timeZone"
                          :label="$t('create_community_session.timezoneLabel')"
                          validation-rules="required"
                        />
                      </div>
                      <div class="time-reminder-text" v-if="timeperiodIsInvalid">Please enter a valid timeperiod!</div>
                    </div>
                  </div>
                  <div class="d-flex flex-column gap-2">
                    <h3>{{ $t('create_community_session.max_attendees') }}</h3>
                    <m-text-field
                      v-model="maxAttendees"
                      :placeholder="'e.g., 250'"
                      validation-rules="between:1,10000"
                    />
                  </div>
                  <div class="d-flex flex-column gap-2">
                    <h3>{{ $t('create_community_session.language') }}</h3>
                    <m-language v-model="language" :multiple="false" :langPriority="userLangPriority" />
                  </div>
                  <div class="d-flex flex-column gap-2">
                    <h3>{{ $t('create_community_session.session_topics') }}</h3>
                    <m-combobox-tags v-model="topics" validation-rules="required" />
                  </div>
                  <div class="d-flex flex-column gap-2">
                    <h3>{{ $t('create_community_session.session_roles') }}</h3>
                    <m-combobox v-model="roles" :items="availableRoles" showSelectAll />
                  </div>
                </div>
              </template>
              <template v-if="currentStep === 3">
                <div class="d-flex align-left flex-column gap-5">
                  <h1>
                    <span>{{ $t('create_community_session.session_overview') }}</span>
                  </h1>
                  <h2>{{ $t('create_community_session.check_session') }}</h2>
                  <v-card class="pa-4" max-width="800px">
                    <a :href="url">{{ url }}</a>
                    <h2>{{ title }}</h2>
                    <p>{{ description }}</p>
                  </v-card>
                  <v-card class="pa-4" max-width="800px">
                    <div class="d-flex flex-row gap-2 details-row">
                      <h3>{{ $t('create_community_session.date_and_time') }}</h3>
                      <p>{{ dateAndTime }}</p>
                    </div>
                    <div v-if="maxAttendees" class="d-flex flex-row gap-2 details-row">
                      <h3>{{ $t('create_community_session.max_attendees') }}:</h3>
                      <p class="align-content-center">{{ maxAttendees }}</p>
                    </div>
                    <div class="d-flex flex-row gap-2 details-row">
                      <h3>{{ $t('create_community_session.language') }}:</h3>
                      <p class="align-content-center">{{ chosenLanguageName }}</p>
                    </div>
                  </v-card>
                  <v-card class="pa-4" max-width="800px">
                    <div class="d-flex flex-row gap-2">
                      <h3 class="align-content-center text-no-wrap">
                        {{ $t('create_community_session.session_topics') }}:
                      </h3>
                      <v-chip-group v-model="topics" class="d-flex" column multiple>
                        <v-chip v-for="(tag, index) in topics" :key="index">{{ tag.name }}</v-chip>
                      </v-chip-group>
                    </div>
                    <div class="d-flex flex-row gap-2" v-if="roles?.length">
                      <h3 class="align-content-center">{{ $t('create_community_session.session_roles') }}:</h3>
                      <v-chip-group v-model="roles" class="d-flex flex-row gap-2">
                        <v-chip v-for="(tag, index) in roles" :key="index">{{ tag }}</v-chip>
                      </v-chip-group>
                    </div>
                  </v-card>
                </div>
              </template>
              <div class="mt-5 d-flex flex-row justify-space-between">
                <v-btn outlined color="button" @click="moveBackOneStep" x-large>{{
                  $t('create_community_session.back')
                }}</v-btn>
                <v-btn color="button" @click="moveToNextStep(invalid)" x-large>{{
                  currentStep === 0
                    ? $t('create_community_session.create_session')
                    : currentStep === 3
                    ? $t('create_community_session.publish_session')
                    : $t('create_community_session.next')
                }}</v-btn>
              </div>
            </div>
          </validation-observer>
        </div>
        <div
          class="info-section d-flex justify-center flex-column pl-6"
          :class="[currentStep === 0 ? 'color-background' : '']"
        >
          <template v-if="currentStep === 0">
            <m-community-session-benifit-card
              :image="require('@/assets/communitySessions/sharing-icon.svg')"
              :title="$t('create_community_session.sharing_title')"
              :subtitle="$t('create_community_session.sharing_subtitle')"
              class="mb-6"
            />
            <m-community-session-benifit-card
              :image="require('@/assets/communitySessions/check-box-icon.svg')"
              :title="$t('create_community_session.networking_title')"
              :subtitle="$t('create_community_session.networking_subtitle')"
              class="mb-6"
            />
            <m-community-session-benifit-card
              :image="require('@/assets/communitySessions/communication-icon.svg')"
              :title="$t('create_community_session.branding_title')"
              :subtitle="$t('create_community_session.branding_subtitle')"
              class="mb-6"
            />
          </template>
        </div>
      </div>
    </m-dialog>
    <m-community-session-succes-dialog />
  </div>
</template>

<script lang="ts">
import {
  ChangeCreateCommunitySessionVisible,
  CommunitySessionsState,
  SaveCommunitySession,
} from '@/store/communitySessions';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import MDialog from '@/components/Dialogs/MDialog';
import { UsersState } from '@/store/users';
import { getFirstName } from '@/utils/user';
import MIcon from '@/components/MIcon';
import {
  MTextField,
  MTextArea,
  MDatePicker,
  MTimePicker,
  MTimeZonePicker,
  MComboboxTags,
  MCombobox,
  MLanguage,
  MBannerImagePicker,
} from '@/components/Inputs';
import MCommunitySessionBenifitCard from '@/components/Cards/MCommunitySessionBenifitCard';
import { TenantEntity } from '@mentessa/types';
import { TenantState } from '@/store/tenant';
import { ValidationObserver } from 'vee-validate';
import { MCommunitySessionSuccesDialog } from '@/components/Dialogs';
import { UIState } from '@/store/ui';
import { fromZonedTime } from 'date-fns-tz';

export default Vue.extend({
  name: 'm-create-community-session-dialog',
  components: {
    MDialog,
    MIcon,
    MTextField,
    MTextArea,
    MCommunitySessionBenifitCard,
    MDatePicker,
    MTimePicker,
    MTimeZonePicker,
    MCombobox,
    MComboboxTags,
    ValidationObserver,
    MCommunitySessionSuccesDialog,
    MLanguage,
    MBannerImagePicker,
  },
  data: () => ({
    url: undefined,
    title: undefined,
    description: undefined,
    language: undefined,
    date: undefined,
    fromTime: undefined,
    toTime: undefined,
    timeZone: undefined,
    maxAttendees: undefined,
    image: undefined,
    currentStep: 0,
    topics: [],
    roles: [],
  }),
  computed: {
    ...mapState<CommunitySessionsState>('communitySessions', {
      createCommunitySessionVisible: (state: CommunitySessionsState) => state.createCommunitySessionVisible,
    }),
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState): TenantEntity => state.tenant,
      tenantRoles: (state: TenantState) => state.tenant?.attributes?.customRoles ?? [],
      forceTimeZone: (state: TenantState) => state.tenant?.attributes?.forceTenantTimeZone ?? false,
      forcedTz: (state: TenantState) => state.tenant?.tz,
    }),
    ...mapGetters('utils', {
      nameForLang: 'nameForLang',
    }),
    ...mapState<UIState>('ui', {
      locale: (state: UIState) => state.translations.dateLocale,
    }),
    show() {
      return this.createCommunitySessionVisible;
    },
    firstName() {
      return getFirstName(this.me);
    },
    dateAndTime() {
      const splitDate = this.date.split('-');
      const month = new Date(this.date).toLocaleString(this.locale, { month: 'short' });
      return `${month} ${splitDate[2]}, ${this.fromTime} - ${this.toTime} ${this.timeZone}`;
    },
    chosenLanguageName() {
      return this.language ? this.nameForLang(this.language) : '';
    },
    timeperiodIsInvalid() {
      if (!this.fromTime || !this.toTime) return false;
      const fromTimeParts = this.fromTime.split(':');
      const convertedFromTime = new Date();
      convertedFromTime.setHours(fromTimeParts[0], fromTimeParts[1], 0, 0);

      const toTimeParts = this.toTime.split(':');
      const convertedToTime = new Date();
      convertedToTime.setHours(toTimeParts[0], toTimeParts[1], 0, 0);

      return convertedFromTime > convertedToTime;
    },
    availableRoles() {
      if (this.tenantRoles.length > 0) {
        return [...this.tenantRoles].sort();
      }
      return [];
    },
    userLangPriority() {
      return this.me?.attributes?.languages || [];
    },
  },
  methods: {
    clean() {
      this.url = undefined;
      this.title = undefined;
      this.description = undefined;
      this.date = undefined;
      this.fromTime = undefined;
      this.toTime = undefined;
      this.timeZone = undefined;
      this.maxAttendees = undefined;
      this.language = undefined;
      this.currentStep = 0;
      this.topics = [];
      this.roles = [];
      this.image = undefined;
      this.$refs.observer.reset();
    },
    closeCreateCommunitySessionDialog() {
      this.$store.dispatch(new ChangeCreateCommunitySessionVisible(false));
      this.clean();
    },
    saveSession() {
      const fromTimeSplit = this.fromTime.split(':');
      const startTime = new Date(this.date);
      startTime.setHours(parseInt(fromTimeSplit[0]), parseInt(fromTimeSplit[1]));

      const endTimeSplit = this.toTime.split(':');
      const endTime = new Date(this.date);
      endTime.setHours(parseInt(endTimeSplit[0]), parseInt(endTimeSplit[1]));

      this.$store.dispatch(
        new SaveCommunitySession({
          meetingUrl: this.url,
          title: this.title,
          description: this.description,
          topics: this.topics,
          attributes: { roles: this.roles ? this.roles : [] },
          maximumAttendees: this.maxAttendees ? this.maxAttendees : undefined,
          date: new Date(this.date),
          startTime: fromZonedTime(startTime, this.timeZone),
          endTime: fromZonedTime(endTime, this.timeZone),
          timeZone: this.timeZone,
          language: this.language,
          bannerImage: this.image,
        }),
      );
    },
    moveToNextStep(invalid) {
      if (invalid) {
        return;
      }

      if (this.currentStep === 2 && this.timeperiodIsValid) {
        return;
      }

      if (this.currentStep === 3 && !this.timeperiodIsValid && !invalid) {
        this.saveSession();
        return;
      }

      this.currentStep++;
    },
    moveBackOneStep() {
      if (this.currentStep === 0) {
        this.closeCreateCommunitySessionDialog();
      } else {
        this.currentStep--;
      }
    },
    changeDate(newDate) {
      this.date = newDate;
    },
    changeFromTime(newTime) {
      this.fromTime = newTime;
    },
    changeToTime(newTime) {
      this.toTime = newTime;
    },
  },
  updated() {
    if (this.tenant && this.$refs.bannerCanvas && this.currentStep === 1) {
      this.fillBannerCanvas();
    }
  },
  mounted() {
    this.timeZone = this.forceTimeZone
      ? this.forcedTz
      : this.me?.tz ?? this.me?.attributes?.tz ?? Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'CET';
  },
  watch: {
    createCommunitySessionVisible(newValue) {
      if (!newValue) {
        this.clean();
      }
    },
    me() {
      if (this.me) {
        this.timeZone = this.forceTimeZone
          ? this.forcedTz
          : this.me?.tz ?? this.me?.attributes?.tz ?? Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'CET';
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
.section-group {
  height: 100%;

  h1 {
    font-size: 32px;
    letter-spacing: 0.01em;

    span {
      color: var(--v-primary-base);
    }
  }

  .form-section {
    width: 60%;
    padding-left: 15vw;
    padding-top: 5vh;

    .card-text {
      color: #000000;
    }
  }

  .info-section {
    width: 40%;
  }

  .color-background {
    background-color: #eee9e2;
  }

  .time-reminder-text {
    font-size: small;
    color: red;
  }

  .details-row {
    p {
      margin-top: 2px;
    }
  }
}
</style>
