<template>
  <m-side-panel-dialog :close="close" :value="open">
    <v-card v-if="!loading" class="m-session-edit-card justify-start d-flex flex-column gap-2">
      <h1>{{ $t('edit_community_session.edit_session_title') }}</h1>
      <v-tabs v-model="tab" show-arrows background-color="background">
        <v-tab key="basic">{{ $t('edit_community_session.basic') }}</v-tab>
        <v-tab key="details">{{ $t('edit_community_session.details') }}</v-tab>
      </v-tabs>
      <v-tabs-items class="mb-4" v-model="tab">
        <v-tab-item>
          <div class="d-flex flex-column mb-6">
            <h3>{{ $t('edit_community_session.link') }}</h3>
            <m-text-field v-model="session.url" :placeholder="'http://zoom.com'" validation-rules="required|url" />
          </div>
          <div class="d-flex flex-column gap-3">
            <m-banner-image-picker v-model="session.image" />
            <div class="d-flex flex-column gap-1">
              <h3>{{ $t('create_community_session.session_title') }}</h3>
              <m-text-field
                v-model="session.title"
                :placeholder="$t('create_community_session.session_title_placeholder')"
                validation-rules="required|min:4|max:60"
              />
            </div>
            <div class="d-flex flex-column gap-1">
              <h3>{{ $t('create_community_session.session_description') }}</h3>
              <m-text-area
                v-model="session.description"
                :placeholder="$t('create_community_session.session_description_placeholder')"
                validation-rules="required|min:30|max:500"
              />
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="d-flex flex-column gap-5">
            <div class="d-flex flex-column gap-2">
              <div class="d-flex flex-row gap-5">
                <div class="d-flex flex-column gap-2">
                  <h3>{{ $t('create_community_session.date') }}</h3>
                  <m-date-picker :value="session.date" @onValueChange="changeDate" validation-rules="required" />
                </div>
                <div class="d-flex flex-column gap-2">
                  <h3>Time</h3>
                  <div class="d-flex flex-row gap-5">
                    <m-time-picker
                      :label="$t('create_community_session.fromTimeLabel')"
                      :value="session.fromTime"
                      @onValueChange="changeFromTime"
                      validation-rules="required"
                    />
                    <m-time-picker
                      :label="$t('create_community_session.toTimeLabel')"
                      :value="session.toTime"
                      @onValueChange="changeToTime"
                      validation-rules="required"
                    />
                    <m-time-zone-picker
                      v-model="session.timeZone"
                      :label="$t('create_community_session.timezoneLabel')"
                      validation-rules="required"
                    />
                  </div>
                </div>
              </div>
              <div class="time-reminder-text" v-if="timeperiodIsInvalid">Please enter a valid timeperiod!</div>
            </div>
            <div class="d-flex flex-column gap-2">
              <h3>{{ $t('create_community_session.max_attendees') }}</h3>
              <m-text-field
                v-model="session.maxAttendees"
                :placeholder="'e.g., 250'"
                validation-rules="between:1,10000"
              />
            </div>
            <div class="d-flex flex-column gap-2">
              <h3>{{ $t('create_community_session.language') }}</h3>
              <m-language v-model="session.language" :multiple="false" :langPriority="userLangPriority" />
            </div>
            <div class="d-flex flex-column gap-2">
              <h3>{{ $t('create_community_session.session_topics') }}</h3>
              <m-combobox-tags v-model="session.topics" validation-rules="required" />
            </div>
            <div class="d-flex flex-column gap-2">
              <h3>{{ $t('create_community_session.session_roles') }}</h3>
              <m-combobox v-model="session.roles" :items="availableRoles" showSelectAll />
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
      <div class="d-flex flex-row justify-space-between button-container">
        <v-btn outlined color="button" @click="close" x-large>{{ $t('edit_community_session.back') }}</v-btn>
        <v-btn color="button" @click="saveSession" x-large>{{ $t('edit_community_session.save') }}</v-btn>
      </div>
    </v-card>
    <v-skeleton-loader v-if="loading" height="100%" type="card" width="100%" />
  </m-side-panel-dialog>
</template>
<script lang="ts">
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { MSidePanelDialog } from '@/components/Dialogs/MSidePanelDialog';
import { TenantState } from '@/store/tenant';
import { LoadCommunintySession, EditCommunitySession } from '@/store/communitySessions';
import { UsersState } from '@/store/users';
import {
  MTextField,
  MTextArea,
  MDatePicker,
  MTimePicker,
  MTimeZonePicker,
  MComboboxTags,
  MCombobox,
  MLanguage,
  MBannerImagePicker,
} from '@/components/Inputs';
import { UIState } from '@/store/ui';
import { TenantEntity } from '@mentessa/types';
import { formatInTimeZone, fromZonedTime, format } from 'date-fns-tz';

export default Vue.extend({
  name: 'm-community-session-edit-dialog',
  components: {
    MSidePanelDialog,
    MTextField,
    MBannerImagePicker,
    MTextArea,
    MDatePicker,
    MTimePicker,
    MTimeZonePicker,
    MComboboxTags,
    MCombobox,
    MLanguage,
  },
  props: {
    open: { type: Boolean, default: false },
    sessionId: Number,
    onClose: Function,
  },
  data: () => ({
    tab: null,
    loading: true,
    bannerUrl: undefined,
    session: {
      id: undefined,
      url: undefined,
      title: undefined,
      description: undefined,
      language: undefined,
      date: undefined,
      fromTime: undefined,
      toTime: undefined,
      timeZone: undefined,
      maxAttendees: undefined,
      image: undefined,
      currentStep: 0,
      topics: [],
      roles: [],
    },
  }),
  computed: {
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState): TenantEntity => state.tenant,
      tenantRoles: (state: TenantState) => state.tenant?.attributes?.customRoles ?? [],
    }),
    ...mapGetters('utils', {
      nameForLang: 'nameForLang',
    }),
    ...mapState<UIState>('ui', {
      locale: (state: UIState) => state.translations.dateLocale,
    }),
    timeperiodIsInvalid() {
      if (!this.session.fromTime || !this.session.toTime) return false;
      const fromTimeParts = this.session.fromTime.split(':');
      const convertedFromTime = new Date();
      convertedFromTime.setHours(fromTimeParts[0], fromTimeParts[1], 0, 0);

      const toTimeParts = this.session.toTime.split(':');
      const convertedToTime = new Date();
      convertedToTime.setHours(toTimeParts[0], toTimeParts[1], 0, 0);

      return convertedFromTime > convertedToTime;
    },
    availableRoles() {
      if (this.tenantRoles.length > 0) {
        return [...this.tenantRoles].sort();
      }
      return [];
    },
    userLangPriority() {
      return this.me?.attributes?.languages || [];
    },
  },
  methods: {
    close() {
      this.session = {
        id: undefined,
        url: undefined,
        title: undefined,
        description: undefined,
        language: undefined,
        date: undefined,
        fromTime: undefined,
        toTime: undefined,
        timeZone: undefined,
        maxAttendees: undefined,
        image: undefined,
        currentStep: 0,
        topics: [],
        roles: [],
      };
      this.onClose();
    },
    changeDate(newDate) {
      this.session.date = newDate;
    },
    changeFromTime(newTime) {
      this.session.fromTime = newTime;
    },
    changeToTime(newTime) {
      this.session.toTime = newTime;
    },
    fillFormFromResponse(sessionData) {
      this.session = {
        id: sessionData.id,
        url: sessionData.meetingUrl,
        title: sessionData.title,
        description: sessionData.description,
        date: format(sessionData.date, 'yyyy-MM-dd'),
        fromTime: formatInTimeZone(sessionData.startTime, sessionData.timeZone, 'HH:mm'),
        toTime: formatInTimeZone(sessionData.endTime, sessionData.timeZone, 'HH:mm'),
        timeZone: sessionData.timeZone,
        maxAttendees: sessionData.maximumAttendees,
        language: sessionData.language,
        topics: sessionData.topics,
        roles: sessionData.attributes?.roles || [],
        image: sessionData.bannerImage,
      };
      this.bannerUrl = sessionData.bannerImage;
    },
    async saveSession() {
      const fromTimeSplit = this.session.fromTime.split(':');
      const startTime = new Date(this.session.date);
      startTime.setHours(parseInt(fromTimeSplit[0]), parseInt(fromTimeSplit[1]));

      const endTimeSplit = this.session.toTime.split(':');
      const endTime = new Date(this.session.date);
      endTime.setHours(parseInt(endTimeSplit[0]), parseInt(endTimeSplit[1]));
      const updateDto = {
        date: new Date(this.session.date),
        startTime: fromZonedTime(startTime, this.session.timeZone),
        endTime: fromZonedTime(endTime, this.session.timeZone),
        meetingUrl: this.session.url,
        title: this.session.title,
        description: this.session.description,
        topics: this.session.topics,
        attributes: { roles: this.session.roles ? this.session.roles : [] },
        maximumAttendees: this.session.maxAttendees ? this.session.maxAttendees : undefined,
        timeZone: this.session.timeZone,
        language: this.session.language,
        bannerImage: undefined,
      };

      if (this.session.image !== this.bannerImage) {
        updateDto.bannerImage = this.session.image;
      }
      this.loading = true;
      const editedSession = await this.$store.dispatch(new EditCommunitySession(this.session.id, updateDto));
      this.fillFormFromResponse(editedSession);
      this.loading = false;
    },
  },
  watch: {
    async open(isOpen) {
      if (isOpen && this.sessionId) {
        this.loading = true;
        const sessionData = await this.$store.dispatch(new LoadCommunintySession(this.tenant, this.sessionId));
        this.fillFormFromResponse(sessionData);
        this.loading = false;
      }
    },
  },
});
</script>
<style lang="scss">
.m-session-edit-card {
  height: calc(100vh - 70px);
  padding: 32px;
  margin-top: 70px;
  border-radius: 18px 18px 0 0 !important;

  .v-btn {
    width: 217px;
    height: 44px !important;
  }
}

.button-container {
  width: 100%;
}
</style>
