<template>
  <div class="d-flex flex-row align-center gap-4">
    <div class="d-flex flex-row align-center">
      <m-user-avatar v-for="attendee in avatarsToShow" :key="attendee.id" large :user="attendee" class="m-avatar" />
    </div>
    <span>{{ attendingLabel + $t('community_session_details_dialog.attending') }}</span>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue';
import { UserEntity } from '@mentessa/types';
import MUserAvatar from '@/components/MUserAvatar';

export default Vue.extend({
  name: 'm-attendee-summary',
  props: {
    attendees: { type: Array as PropType<UserEntity[]>, default: () => [] },
    maxAttendees: { type: Number, default: undefined },
  },
  components: {
    MUserAvatar,
  },
  computed: {
    avatarsToShow() {
      return this.attendees.slice(0, 3);
    },
    attendingLabel() {
      return `${this.attendees.length}${this.maxAttendees ? '/' + this.maxAttendees : ''} `;
    },
  },
});
</script>
<style scoped lang="scss">
.m-avatar {
  margin-left: -25px;
}
</style>
