import { GetterTree } from 'vuex';
import { TenantState } from '@/store/tenant/types';
import { RootState } from '@/store/types';
import { Features, Locale, UserReference } from '@mentessa/types';
import { usersGetter } from '@/store/users';

export const getters: GetterTree<TenantState, RootState> = {
  // Warning: It's a deprecated method, do not use it
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemsForSource: (state: TenantState) => (source: string) => {
    alert(state.tenant);
    const card = state.tenant.config.userCard.fields.find((card) => card.name === source);
    if (card && card.items) return card.items.map((item) => ({ name: item }));
    return state.tenant[source];
  },
  isFeatureEnabled: (state: TenantState) => (feature: Features) => {
    return state.tenant.config.features[feature]?.enabled ?? false;
  },
  featureConfig: (state: TenantState) => (feature: Features) => {
    return state.tenant.config.features[feature] ?? { enabled: false };
  },
  isCommunityRulesEnabled(state: TenantState, getters) {
    return getters.isFeatureEnabled('communityRules');
  },
  isCommunitySessionsEnabled(state: TenantState, getters) {
    return getters.isFeatureEnabled('communitySessions');
  },
  isDashboardAvailable(state: TenantState, getters, rootState: RootState, rootGetters) {
    return getters.isFeatureEnabled('dashboard') && rootGetters[usersGetter('canRead')]('dashboard');
  },
  isMembersAvailable(state: TenantState, getters, rootState: RootState, rootGetters) {
    return rootGetters[usersGetter('canWrite')]('invites');
  },
  isInvitesAvailable(state: TenantState, getters, rootState: RootState, rootGetters) {
    return (
      getters.isFeatureEnabled('invites') &&
      !getters.featureConfig('users')?.sso &&
      rootGetters[usersGetter('canWrite')]('invites')
    );
  },
  isCustomizationAvailable(state: TenantState, getters, rootState: RootState, rootGetters) {
    return getters.isFeatureEnabled('customization') && rootGetters[usersGetter('canWrite')]('customization');
  },
  isMessagesAvailable(state: TenantState, getters) {
    return getters.isFeatureEnabled('messages');
  },
  isCommunityRulesAvailable(state: TenantState, getters) {
    return (getters.isFeatureEnabled('communityRules') && state.tenant?.attributes?.communityRules?.enabled) ?? false;
  },
  isCommunityRulesRequired(state: TenantState) {
    return state.tenant?.attributes?.communityRules?.required ?? false;
  },
  isCommunityEmpty(state: TenantState) {
    if (state.tenant?.activeMembersCount == null) {
      return false;
    }

    return state.tenant.activeMembersCount <= 1;
  },
  isProfileAvailable(state: TenantState, getters) {
    return getters.isFeatureEnabled('profile');
  },
  isSettingsAvailable(state: TenantState, getters) {
    return getters.isCustomizationAvailable || getters.isInvitesAvailable || getters.isRitualsAvailable;
  },
  isMentoringAvailable(state: TenantState, getters) {
    return getters.isFeatureEnabled('mentoring');
  },
  isUserIntegrationsAvailable(state: TenantState, getters) {
    return getters.isFeatureEnabled('userIntegrations');
  },
  isScheduleAvailable(state: TenantState, getters) {
    return getters.isFeatureEnabled('mentoring') && getters.featureConfig('mentoring').schedule;
  },
  isBookingAvailable(state: TenantState, getters) {
    return getters.isFeatureEnabled('mentoring') && getters.featureConfig('mentoring').booking;
  },
  isMatchingAvailable(state: TenantState, getters) {
    return getters.isFeatureEnabled('matching');
  },
  isSelfMatchingAvailable(state: TenantState, getters) {
    return getters.isMatchingAvailable && state.tenant.config.features.matching.selfMatching;
  },
  isGoalsAvailable(state: TenantState, getters) {
    return getters.isFeatureEnabled('goals');
  },
  isRitualsEnabled(state: TenantState, getters) {
    return getters.isFeatureEnabled('rituals');
  },
  isRitualsAvailable(state: TenantState, getters, rootState: RootState, rootGetters) {
    return getters.isFeatureEnabled('rituals') && rootGetters[usersGetter('canWrite')]('rituals');
  },
  isRitualsShopAvailable(state: TenantState, getters) {
    return getters.isFeatureEnabled('rituals') && getters.featureConfig('rituals').shop === true;
  },
  isRitualExecuteAvailable(state: TenantState, getters, rootState: RootState, rootGetters) {
    return getters.isFeatureEnabled('rituals') && rootGetters[usersGetter('canWrite')]('rituals');
  },
  isRitualsManagingAvailable(state: TenantState, getters, rootState: RootState, rootGetters) {
    return getters.isFeatureEnabled('rituals') && rootGetters[usersGetter('canWrite')]('rituals');
  },
  isHelloAvailable(state: TenantState, getters) {
    return getters.isFeatureEnabled('hello');
  },
  isBadgesAvailable(state: TenantState, getters) {
    return getters.isFeatureEnabled('badges');
  },
  isDebugAvailable(state: TenantState, getters, rootState: RootState, rootGetters) {
    return rootGetters[usersGetter('canRead')]('debug');
  },
  isIntegrationsEnabled(state: TenantState, getters) {
    return getters.isFeatureEnabled('integrations');
  },
  isMobileEnabled(state: TenantState, getters) {
    return getters.isFeatureEnabled('mobile');
  },
  isResourcesEnabled(state: TenantState, getters) {
    return getters.isFeatureEnabled('resources');
  },
  isResourcesAvailable(state: TenantState, getters, rootState: RootState, rootGetters) {
    return getters.isFeatureEnabled('resources') && rootGetters[usersGetter('canRead')]('resources');
  },
  isContentAvailable(state: TenantState, getters, rootState: RootState, rootGetters) {
    return getters.isFeatureEnabled('content') && rootGetters[usersGetter('canRead')]('content');
  },
  isNpsAvailable(state: TenantState, getters, rootState: RootState, rootGetters) {
    return getters.isFeatureEnabled('nps') && rootGetters[usersGetter('canRead')]('nps');
  },
  isNpsAndNpsManagingAvailable(state: TenantState, getters, rootState: RootState, rootGetters) {
    return (
      getters.isFeatureEnabled('nps') &&
      rootGetters[usersGetter('canRead')]('nps') &&
      rootGetters[usersGetter('canWrite')]('nps')
    );
  },
  isContentManagingAvailable(state: TenantState, getters, rootState: RootState, rootGetters) {
    return getters.isFeatureEnabled('content') && rootGetters[usersGetter('canWrite')]('content');
  },
  isResourcesManagingAvailable(state: TenantState, getters, rootState: RootState, rootGetters) {
    return getters.isFeatureEnabled('resources') && rootGetters[usersGetter('canWrite')]('resources');
  },
  isQuickFiltersAvailable(state: TenantState, getters) {
    return getters.isFeatureEnabled('quickFilters');
  },
  isHomeAvailable(state: TenantState, getters) {
    return getters.isFeatureEnabled('home');
  },
  resourcesTags(state: TenantState) {
    return state.tenant.config.features['resources']?.tags ?? [];
  },
  contentTags(state: TenantState) {
    return state.tenant.config.features['content']?.tags ?? [];
  },
  availableLocales(state: TenantState) {
    return state.tenant?.config?.locales?.available ?? [];
  },
  isLocaleAvailable(state: TenantState, getters) {
    return (locale: string) => {
      if (!locale) return false;
      return getters.availableLocales.includes(locale as Locale) ?? false;
    };
  },
  defaultLocale(state: TenantState, getters) {
    const browserLocale = navigator.language?.split('-')[0] as Locale;
    if (getters.availableLocales.includes(browserLocale)) {
      return browserLocale;
    }

    return state.tenant.config.locales.default ?? ['en'];
  },
  isFilterEnabled(state: TenantState) {
    return (name: string, defaultValue = true): boolean => {
      return state.tenant.attributes?.filters?.[name]?.enabled ?? defaultValue;
    };
  },
  filterConfig(state: TenantState) {
    return (name: string) => {
      return state.tenant.attributes?.filters?.[name] ?? {};
    };
  },
  hiddenRoles(state: TenantState) {
    return state.tenant.attributes?.hiddenRoles ?? [];
  },
  hideCompletionBanner(state: TenantState, getters, rootState: RootState, rootGetters): boolean {
    const hideCompletionBanner = state.tenant.attributes?.hideCompletionBanner ?? false;
    const completionPercentage = rootGetters[usersGetter('completionPercentage')];

    return hideCompletionBanner || completionPercentage > 75;
  },
  getColorPalette(state) {
    if (state.tenant.attributes?.theme?.palette?.length > 2) {
      return state.tenant.attributes.theme.palette;
    }
    return [
      '#7a00d6',
      '#fed4ff',
      '#e0ff25',
      '#cdb3f4',
      '#eee9e2',
      '#a3b6f6',
      '#ffb932',
      //
    ];
  },
  getAvatarColor(state, getters) {
    const colors = getters.getColorPalette;
    return (user: UserReference): string => {
      if (user.id == null) return colors[0];
      return colors[user.id % colors.length];
    };
  },
  isSpecial(state: TenantState) {
    return state.tenant.attributes?.special ?? false;
  },
  logoutUrl(state: TenantState) {
    return state.tenant.keycloak?.logoutUrl ?? window.location.origin;
  },
};
