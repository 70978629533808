<template>
  <v-row class="ma-0 mt-8 gap-4">
    <m-community-session-card
      v-for="upcommingSession in upcommingSessions"
      :key="upcommingSession.id"
      :groupSession="upcommingSession"
      :onCardClick="sessionCardClicked"
    />
    <div v-if="!loading" v-observe-visibility="visibilityChanged" class="sentinel" />
    <v-overlay :opacity="0.7" :value="loading" absolute color="var(--v-white-base)">
      <m-loader :isLoading="loading" />
    </v-overlay>
    <m-community-session-dialog
      :sessionId="selectedCommunitySessionId"
      :open="detailsDialogOpen"
      :onClose="closeDetailsDialog"
    />
  </v-row>
</template>
<script lang="ts">
import Vue from 'vue';
import MLoader from '@/components/MLoader';
import MCommunitySessionCard from '@/components/Cards/MCommunitySessionCard';
import { MCommunitySessionDialog } from '@/components/Dialogs';
import { TenantState } from '@/store/tenant';
import { LoadUpcomingCommunintySessionPage } from '@/store/communitySessions';
import { mapState } from 'vuex';
import { RouteName } from '@/router/types';

export default Vue.extend({
  name: 'm-community-session-stream',
  components: { MCommunitySessionCard, MLoader, MCommunitySessionDialog },
  data: () => ({
    loading: false,
    upcommingSessions: [],
    pageData: undefined,
    selectedCommunitySessionId: undefined,
    detailsDialogOpen: false,
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState) => state.tenant,
    }),
    isLastPage() {
      if (this.pageData === undefined) return false;
      return this.pageData?.currentPage >= this.pageData?.totalPages;
    },
  },
  async mounted() {
    this.loadMoreSessions();
    if (this.$route.query.session) {
      this.selectedCommunitySessionId = Number(this.$route.query.session);
      this.detailsDialogOpen = true;
    }
  },
  methods: {
    async loadMoreSessions() {
      if (!this.isLastPage && !this.loading) {
        try {
          this.loading = true;

          let pageToLoad = 1;

          if (this.pageData) {
            pageToLoad = this.pageData.currentPage + 1;
          }

          const upcommingSessionsData = await this.$store.dispatch(
            new LoadUpcomingCommunintySessionPage(this.tenant, pageToLoad),
          );
          this.upcommingSessions.push(...upcommingSessionsData.items);
          this.pageData = upcommingSessionsData.meta;
        } finally {
          this.loading = false;
        }
      }
    },
    visibilityChanged(isVisible) {
      if (isVisible && !this.isLastPage) {
        this.loadMoreSessions();
      }
    },
    async sessionCardClicked(session) {
      this.selectedCommunitySessionId = session.id;
      this.detailsDialogOpen = true;
      this.$router.push({
        name: RouteName.CommunitySessions,
        query: { session: `${session.id}` },
      });
    },
    closeDetailsDialog() {
      this.detailsDialogOpen = false;
      this.$router.push({
        name: RouteName.CommunitySessions,
      });
    },
  },
});
</script>
<style lang="scss">
.sentinel {
  list-style-type: none;
  width: 100%;
}
</style>
