<template>
  <section>
    <v-tabs v-model="tab" show-arrows background-color="background">
      <v-tab key="upcoming">{{ $t('appointments.tabs.upcoming') }}</v-tab>
      <v-tab key="past">{{ $t('appointments.tabs.past') }}</v-tab>
      <v-tab key="cancelled">{{ $t('appointments.tabs.cancelled') }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <m-group-session-cards v-if="upcomingSessions.length > 0" :sessions="upcomingSessions" />
        <h3 class="mt-9 m-section-lable">{{ $t('session.one_on_one_sessoins') }}</h3>
        <div v-if="upcomingAppointments.length > 0" class="mt-2 gap-4 d-flex flex-column">
          <m-appointment-card
            v-for="appointment in upcomingAppointments"
            :key="appointment.id"
            :appointment="appointment"
            :user="getMatchUser(appointment)"
            @remove-match="removeMatch"
          ></m-appointment-card>
        </div>
        <div v-else>
          <m-empty-community v-if="isCommunityEmpty && isInvitesAvailable" />
          <m-empty-state
            v-else
            :cta="$t('appointments.empty.upcoming.cta')"
            :description="$t('appointments.empty.upcoming.description')"
            :image="require('@/assets/empty/calendar.svg')"
            :title="$t('appointments.empty.upcoming.title')"
            @onButtonClick="goToCommunity"
          />
        </div>
      </v-tab-item>
      <v-tab-item>
        <m-group-session-cards v-if="pastSessions.length > 0" :sessions="pastSessions" />
        <h3 class="mt-9 m-section-lable">{{ $t('session.one_on_one_sessoins') }}</h3>
        <div v-if="pastAppointments.length > 0" class="mt-2 gap-4 d-flex flex-column">
          <m-appointment-card
            v-for="appointment in pastAppointments"
            :key="appointment.id"
            :appointment="appointment"
            :user="getMatchUser(appointment)"
            @remove-match="removeMatch"
          ></m-appointment-card>
        </div>
        <div v-else>
          <p class="text-center mt-14">{{ $t('appointments.empty.past.title') }}</p>
        </div>
      </v-tab-item>
      <v-tab-item>
        <m-group-session-cards v-if="canceledSessions.length > 0" :sessions="canceledSessions" />
        <h3 class="mt-9 m-section-lable">{{ $t('session.one_on_one_sessoins') }}</h3>
        <div v-if="canceledAppointments.length > 0" class="mt-9 gap-4 d-flex flex-column">
          <m-appointment-card
            v-for="appointment in pastAppointments"
            :key="appointment.id"
            :appointment="appointment"
            :user="getMatchUser(appointment)"
            @remove-match="removeMatch"
          ></m-appointment-card>
        </div>
        <div v-else>
          <p class="text-center mt-14">{{ $t('appointments.empty.cancelled.title') }}</p>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </section>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { UsersState } from '@/store/users';
import { ClearBookedSession } from '@/store/matching';
import {
  AppointmentStatus,
  CommunitySessionStatus,
  IAppointment,
  ICommunitySession,
  IUser,
  MentoringSessionStatus,
} from '@mentessa/types';
import MAppointmentCard from '@/components/MAppointmentCard';
import { isAfter, isBefore, parseISO, compareAsc } from 'date-fns';
import MEmptyState from '@/components/MEmptyState';
import MEmptyCommunity from '@/components/MEmptyCommunity';
import { RouteName } from '@/router/types';
import MGroupSessionCards from './MGroupSessionCards.vue';

export default Vue.extend({
  name: 'm-sessions-list',
  components: { MEmptyCommunity, MEmptyState, MAppointmentCard, MGroupSessionCards },
  data: () => ({
    tab: null,
  }),
  props: {
    appointments: { type: Array as PropType<IAppointment[]> },
    groupSessions: { type: Array as PropType<ICommunitySession[]> },
  },
  computed: {
    ...mapGetters('tenant', {
      isInvitesAvailable: 'isInvitesAvailable',
      isCommunityEmpty: 'isCommunityEmpty',
    }),
    ...mapState<UsersState>('users', {
      me: (state: UsersState): IUser => state.me,
    }),
    upcomingAppointments() {
      return this.appointments.filter((appointment: IAppointment) => {
        const appointmentDate: string = appointment.date?.toString() ?? appointment.slot?.date.toString();
        return appointment.status !== AppointmentStatus.Canceled && isAfter(parseISO(appointmentDate), new Date());
      });
    },
    pastAppointments() {
      return this.appointments.filter((appointment: IAppointment) => {
        const appointmentDate: string = appointment.date?.toString() ?? appointment.slot?.date.toString();
        return appointment.status !== AppointmentStatus.Canceled && isBefore(parseISO(appointmentDate), new Date());
      });
    },
    canceledAppointments() {
      return this.appointments.filter((appointment: IAppointment) => appointment.status === AppointmentStatus.Canceled);
    },
    currentAppointments() {
      if (this.tab === 0) {
        return this.upcomingAppointments;
      } else if (this.tab === 1) {
        return this.pastAppointments;
      }
      return this.canceledAppointments;
    },
    upcomingSessions() {
      return this.groupSessions
        .filter((session: ICommunitySession) => {
          const sessionDate: string = session.endTime.toString();
          return session.status !== CommunitySessionStatus.Canceled && isAfter(parseISO(sessionDate), new Date());
        })
        .sort((sessionOne: ICommunitySession, sessionTwo: ICommunitySession) => {
          return compareAsc(sessionOne.startTime, sessionTwo.startTime);
        });
    },
    pastSessions() {
      return this.groupSessions
        .filter((session: ICommunitySession) => {
          const sessionDate: string = session.endTime.toString();
          return session.status !== CommunitySessionStatus.Canceled && isBefore(parseISO(sessionDate), new Date());
        })
        .sort((sessionOne: ICommunitySession, sessionTwo: ICommunitySession) => {
          return compareAsc(sessionOne.startTime, sessionTwo.startTime);
        });
    },
    canceledSessions() {
      return this.groupSessions
        .filter((appointment: ICommunitySession) => appointment.status === CommunitySessionStatus.Canceled)
        .sort((sessionOne: ICommunitySession, sessionTwo: ICommunitySession) => {
          return compareAsc(sessionOne.startTime, sessionTwo.startTime);
        });
    },
    readOnly() {
      return this.session?.status !== MentoringSessionStatus.Progress;
    },
  },
  methods: {
    getMatchUser(appointment: IAppointment) {
      if (appointment.organizerId === this.me.id) {
        return appointment.target;
      }
      return appointment.organizer;
    },
    async removeMatch(appointment: IAppointment) {
      await this.$store.dispatch(new ClearBookedSession(this.me, appointment));
    },
    async goToCommunity() {
      await this.$router.push({ name: RouteName.Community });
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
.m-section-lable {
  color: var(--v-primary-base);
}
</style>
