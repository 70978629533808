"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentType = void 0;
var VideoType;
(function (VideoType) {
    VideoType["Raw"] = "raw";
    VideoType["YouTube"] = "youtube";
})(VideoType || (VideoType = {}));
var ContentType;
(function (ContentType) {
    ContentType["News"] = "news";
    ContentType["Video"] = "video";
})(ContentType = exports.ContentType || (exports.ContentType = {}));
