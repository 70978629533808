"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CasbinRole = void 0;
var CasbinRole;
(function (CasbinRole) {
    CasbinRole["Anybody"] = "*";
    CasbinRole["Admin"] = "Admins";
    CasbinRole["DataAdmin"] = "DataAdmins";
    CasbinRole["SuperAdmin"] = "SuperAdmins";
    CasbinRole["Emails"] = "Emails";
    CasbinRole["RitualsAdmin"] = "RitualsAdmins";
    CasbinRole["UsersAdmin"] = "UsersAdmins";
    CasbinRole["ContentAdmin"] = "ContentAdmins";
    CasbinRole["ResourcesAdmin"] = "ResourcesAdmins";
})(CasbinRole = exports.CasbinRole || (exports.CasbinRole = {}));
