<template>
  <div>
    <v-card class="mt-3 mb-3 pa-4" @click="onCardClick(groupSession)">
      <div class="d-flex flex-row justify-space-between align-center">
        <div class="d-flex flex-column gap-2">
          <div class="d-flex flex-row gap-2">
            <m-user-preview :user="groupSession.createdBy" />
            <span class="align-self-center">&#8226; {{ formattedDurarion }}</span>
          </div>
          <div class="d-flex flex-row justify-space-between align-center">
            <h2>{{ groupSession.title }}</h2>
          </div>
        </div>
        <div class="d-flex flex-row align-center gap-1">
          <v-btn class="starting-soon-indicator" color="#44dd54" v-if="startingSoon">{{
            $t('session.starting_soon')
          }}</v-btn>
          <v-menu v-if="isMySession" location="bottom" min-width="128px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="openEditDialog" ripple>
                <v-list-item-title>
                  {{ $t(`session.edit_session`) }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-card>
    <m-community-session-edit-dialog :sessionId="groupSession.id" :open="editDialogOpen" :onClose="closeEditDialog" />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { CommunitySessionStatus, ICommunitySession } from '@mentessa/types';
import { isAfter, parseISO } from 'date-fns';
import MUserPreview from '@/components/MUserPreview';
import { MCommunitySessionEditDialog } from '@/components/Dialogs';
import { UsersState } from '@/store/users';

export default Vue.extend({
  name: 'm-community-session-row-card',
  components: {
    MUserPreview,
    MCommunitySessionEditDialog,
  },
  props: {
    groupSession: { type: Object as PropType<ICommunitySession> },
    onCardClick: Function,
  },
  data: () => ({
    editDialogOpen: false,
  }),
  computed: {
    ...mapGetters('ui', {
      formatInUserTimeZone: 'formatInUserTimeZone',
    }),
    ...mapState<UsersState>('users', {
      me: (state: UsersState) => state.me,
    }),
    formattedStart() {
      return this.formatInUserTimeZone(this.groupSession.startTime, 'MMM d, H:mm');
    },
    formattedEnd() {
      return this.formatInUserTimeZone(this.groupSession.endTime, 'H:mm (O)');
    },
    formattedDurarion() {
      return `${this.formattedStart} - ${this.formattedEnd}`;
    },
    startingSoon() {
      const sessionDate: string = this.groupSession.startTime.toString();
      return this.groupSession.status !== CommunitySessionStatus.Canceled && isAfter(parseISO(sessionDate), new Date());
    },
    isMySession() {
      return this.groupSession?.createdBy.id === this.me.id;
    },
  },
  methods: {
    openEditDialog() {
      this.editDialogOpen = true;
    },
    closeEditDialog() {
      this.editDialogOpen = false;
    },
  },
});
</script>
<style lang="scss" scoped>
.starting-soon-indicator {
  color: #ffffff;
}
</style>
