<template>
  <div>
    <m-profile-completion-banner class="mb-8" extended />
    <v-tabs v-model="tab" class="mb-6" background-color="background" v-if="areTabsVisible">
      <v-tab key="people" @click="onMathingSelected">
        <h1>{{ $t('community.people') }}</h1>
      </v-tab>
      <v-tab key="sessions" @click="onSessionsSelected">
        <h1>{{ $t('community.sessions') }}</h1>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" v-if="areTabsVisible">
      <v-tab-item>
        <m-user-stream />
      </v-tab-item>
      <v-tab-item>
        <m-community-session-stream />
      </v-tab-item>
    </v-tabs-items>
    <m-user-stream v-if="!areTabsVisible" />
    <m-community-session-stream v-if="!areTabsVisible && isCommunitySessionsEnabled" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import MUserStream from '@/components/MUserStream/MUserStream.vue';
import MCommunitySessionStream from '@/components/MCommunitySessionStream';
import { MProfileCompletionBanner } from '@/components/MBanner';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'm-community',
  components: {
    MUserStream,
    MProfileCompletionBanner,
    MCommunitySessionStream,
  },
  props: {
    sessions: { type: Boolean, default: false },
  },
  data: () => ({
    tab: null,
  }),
  computed: {
    ...mapGetters('tenant', {
      isCommunitySessionsEnabled: 'isCommunitySessionsEnabled',
    }),
    areTabsVisible() {
      return (
        [
          true, // Community
          this.isCommunitySessionsEnabled,
        ].filter(Boolean).length > 1
      );
    },
  },
  mounted() {
    if (this.sessions) {
      this.tab = 1;
    }
  },
  methods: {
    onMathingSelected() {
      history.pushState({}, null, '/community');
    },
    onSessionsSelected() {
      history.pushState({}, null, '/community/sessions');
    },
  },
});
</script>
<style lang="scss">
.v-tab {
  padding-left: 0px;
  margin-right: 16px;
}
</style>
