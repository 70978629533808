<template>
  <div>
    <h3 class="mt-9 m-section-lable">{{ $t('session.group_sessoins') }}</h3>
    <m-community-session-row-card
      v-for="session in sessions"
      :key="session.id"
      :groupSession="session"
      :onCardClick="sessionCardClicked"
    />
    <m-community-session-dialog
      :sessionId="selectedCommunitySessionId"
      :open="detailsDialogOpen"
      :onClose="closeDetailsDialog"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import { ICommunitySession } from '@mentessa/types';
import { MCommunitySessionRowCard } from '@/components/Cards/MCommunitySessionCard';
import { MCommunitySessionDialog } from '@/components/Dialogs';

export default Vue.extend({
  name: 'm-group-session-cards',
  components: { MCommunitySessionRowCard, MCommunitySessionDialog },
  props: {
    sessions: { type: Array as PropType<ICommunitySession[]> },
  },
  data: () => ({
    detailsDialogOpen: false,
    selectedCommunitySessionId: undefined,
  }),
  methods: {
    closeDetailsDialog() {
      this.detailsDialogOpen = false;
    },
    sessionCardClicked(session) {
      this.selectedCommunitySessionId = session.id;
      this.detailsDialogOpen = true;
    },
  },
});
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.m-section-lable {
  color: var(--v-primary-base);
}
</style>
