"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MatchStatus = exports.MatchSource = void 0;
var MatchSource;
(function (MatchSource) {
    MatchSource["Manual"] = "manual";
    MatchSource["Ritual"] = "ritual";
    MatchSource["Self"] = "self";
})(MatchSource = exports.MatchSource || (exports.MatchSource = {}));
var MatchStatus;
(function (MatchStatus) {
    MatchStatus["Pending"] = "pending";
    MatchStatus["Accepted"] = "accepted";
    MatchStatus["Declined"] = "declined";
    MatchStatus["Expired"] = "expired";
    MatchStatus["Closed"] = "closed";
})(MatchStatus = exports.MatchStatus || (exports.MatchStatus = {}));
