import { Action } from '@/store/types';
import { ICommunitySession, ICreateCommunitySession, TenantReference, UserReference } from '@mentessa/types';

export const moduleName = 'communitySessions';

interface CreateCommunitySessionPayload extends ICreateCommunitySession {
  bannerImage: Blob;
}

export interface CommunitySessionsState {
  createCommunitySessionVisible: boolean;
  newSessionId?: number;
  sessionsCreatedByUser: ICommunitySession[];
}

export enum CommunitySessionsActions {
  ChangeCreateCommunitySessionVisible = '[CommunitySessions] Change Create Community Session Visible',
  SaveCommunitySession = '[CommunitySessions] Save Community Session',
  EditCommunitySession = '[CommunitySessions] Edit Community Session',
  CommunitySessionCreatedSuccessfully = '[CommunitySessions] Community Session Created Successfully',
  CloseCommunitySessionCreatedSuccessfull = '[CommunitySessions] Close Community Session Created Successfull',
  LoadCommunitySessionsForUser = '[CommunitySessions] Load Community Sessions For User',
  LoadUpcomingCommunintySessionPage = '[CommunitySessions] Load Upcoming Community Sessions',
  LoadCommunintySession = '[CommunitySessions] Load Community Session',
  SubscribeToCommunitySession = '[CommunitySessions] Subscribe To Community Session',
  UnsubscribeToCommunitySession = '[CommunitySessions] Unsubscribe To Community Session',
}

export enum CommunitySessionsMutations {
  SetCreateCommunitySessionVisible = '[CommunitySessions] Change Create Community Session Visible',
  SetNewCommunitySessionId = '[CommunitySessions] Change New Community Session Id',
  SetCommunitySessionForUser = '[CommunitySessions] Set Community Sessions For User',
}

export class ChangeCreateCommunitySessionVisible implements Action {
  type = `${moduleName}/${CommunitySessionsActions.ChangeCreateCommunitySessionVisible}`;

  constructor(private readonly visible: boolean) {}
}

export class SaveCommunitySession implements Action {
  type = `${moduleName}/${CommunitySessionsActions.SaveCommunitySession}`;

  constructor(private readonly dto: CreateCommunitySessionPayload, private readonly tenantRef?: TenantReference) {}
}

export class EditCommunitySession implements Action {
  type = `${moduleName}/${CommunitySessionsActions.EditCommunitySession}`;

  constructor(
    private readonly id: number,
    private readonly dto: CreateCommunitySessionPayload,
    private readonly tenantRef?: TenantReference,
  ) {}
}

export class CommunitySessionCreatedSuccessfully implements Action {
  type = `${moduleName}/${CommunitySessionsActions.CommunitySessionCreatedSuccessfully}`;

  constructor(private readonly sessionId: number) {}
}

export class CloseCommunitySessionCreatedSuccessfull implements Action {
  type = `${moduleName}/${CommunitySessionsActions.CloseCommunitySessionCreatedSuccessfull}`;
}

export class LoadCommunitySessionsForUser implements Action {
  type = `${moduleName}/${CommunitySessionsActions.LoadCommunitySessionsForUser}`;

  constructor(private readonly tenantRef?: TenantReference, private readonly userRef?: UserReference) {}
}

export class LoadUpcomingCommunintySessionPage implements Action {
  type = `${moduleName}/${CommunitySessionsActions.LoadUpcomingCommunintySessionPage}`;

  constructor(private readonly tenantRef: TenantReference, private readonly page: number) {}
}

export class LoadCommunintySession implements Action {
  type = `${moduleName}/${CommunitySessionsActions.LoadCommunintySession}`;

  constructor(private readonly tenantRef: TenantReference, private sessionId: number) {}
}
export class SubscribeToCommunitySession implements Action {
  type = `${moduleName}/${CommunitySessionsActions.SubscribeToCommunitySession}`;

  constructor(private readonly tenantRef: TenantReference, private readonly sessionId: number) {}
}

export class UnsubscribeToCommunitySession implements Action {
  type = `${moduleName}/${CommunitySessionsActions.UnsubscribeToCommunitySession}`;

  constructor(private readonly tenantRef: TenantReference, private readonly sessionId: number) {}
}
