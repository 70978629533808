<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.xsOnly"
    content-class="m-side-panel-dialog"
    scrollable
    sentry-tag="m-side-panel-dialog"
    transition="slide-x-reverse-transition"
    :value="value"
    width="746"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card class="m-side-panel-header">
      <v-img v-if="backgroundImageUrl" :src="backgroundImageUrl" />
      <div class="m-icon-container">
        <a sentry-tag="side-panel-dialog-content.button.close" @click="close">
          <v-img :src="closeImage" contain height="24" width="24" />
        </a>
      </div>
      <slot />
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'm-side-panel-dialog',
  props: {
    close: { type: Function },
    value: { type: Boolean },
    backgroundImageUrl: { type: String, default: undefined },
  },
  computed: {
    closeImage() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return require('@/assets/arrow-left.svg');
      }
      return require('@/assets/close-icon.svg');
    },
  },
});
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

:deep(.m-side-panel-dialog) {
  position: fixed;
  right: 0;
  margin: 0;
  border-radius: 0;
  max-height: 100%;
  height: 100%;

  .v-sheet.v-card {
    border-radius: 0;
  }

  .m-side-panel-header {
    background-color: var(--v-beige-base);
  }
}

.m-icon-container {
  padding: 10px;
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 1;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    left: 4px;
  }
}
</style>
