<template>
  <div sentry-tag="image-picker" class="m-banner-image-picker__container">
    <canvas ref="bannerCanvas" width="600" height="200"></canvas>
    <v-file-input
      ref="fileInput"
      accept="image/*"
      class="d-none"
      @change="loadImage($event)"
      sentry-tag="image-picker.input.image"
    />
    <v-btn
      class="ml-4 m-banner-image-picker__open-picker"
      depressed
      @click="triggerImagePicker"
      sentry-tag="image-picker.button.triggerImagePicker"
      color="primary"
    >
      {{ $t('create_community_session.upload_banner') }}
    </v-btn>
    <v-dialog v-model="showCropperDialog" height="600" width="600">
      <div class="m-image-picker__cropper-container">
        <cropper
          ref="cropper"
          :src="pickedImage.src"
          :stencil-props="{
            handlers: {},
            movable: false,
            resizable: false,
            aspectRatio: 3,
          }"
          :stencil-size="{ width: 600, height: 200 }"
          class="m-image-picker__cropper"
          image-restriction="stencil"
        />
        <div
          class="m-image-picker__cropper-close"
          @click="showCropperDialog = false"
          sentry-tag="image-picker.button.close"
        >
          <v-icon color="primary"> close</v-icon>
        </div>
        <div class="m-image-picker__cropper-apply">
          <v-btn
            class="ma-2 ml-1"
            color="button"
            depressed
            small
            @click.stop="applyImage"
            sentry-tag="image-picker.button.apply"
          >
            {{ $t('components.imagePicker.apply') }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import Pica from 'pica';
import { canvasToBlob, getMimeType } from '@/utils/image';
import { TenantState } from '@/store/tenant';
import { TenantEntity } from '@mentessa/types';
import { mapGetters, mapState } from 'vuex';

export default Vue.extend({
  name: 'm-banner-image-picker',
  components: { Cropper },
  props: {
    value: undefined,
  },
  data: () => ({
    showCropperDialog: false,
    // Image that is picked by file-input
    pickedImage: {
      src: undefined,
      type: undefined,
    },
    // Image that is picket by cropper
    croppedImage: undefined,
    processing: false,
  }),
  computed: {
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState): TenantEntity => state.tenant,
    }),
    ...mapGetters('tenant', {
      getColorPalette: 'getColorPalette',
    }),
  },
  destroyed() {
    if (this.pickedImage.src) {
      URL.revokeObjectURL(this.pickedImage.src);
    }
  },
  mounted() {
    this.fillBannerCanvas();
  },
  methods: {
    triggerImagePicker() {
      this.$refs.fileInput.$refs.input.click();
    },
    loadImage(file: File) {
      if (file) {
        if (this.pickedImage.src) {
          URL.revokeObjectURL(this.pickedImage.src);
        }
        const blob = URL.createObjectURL(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          this.pickedImage = {
            src: blob,
            type: getMimeType(e.target.result as ArrayBuffer, file.type),
          };
          this.showCropperDialog = true;
        };
        reader.readAsArrayBuffer(file);
      }
    },
    async fillBannerCanvas() {
      const canvas = this.$refs.bannerCanvas;
      const canvasContext = canvas.getContext('2d');

      if (this.value) {
        const image = new Image(600, 200);
        image.addEventListener('load', () => {
          canvasContext.drawImage(image, 0, 0);
        });
        image.src = this.value;
      } else if (this.getColorPalette) {
        const colors = this.getColorPalette;
        const radonColorIndex = Math.floor(Math.random() * colors.length);
        canvasContext.fillStyle = colors[radonColorIndex];
        canvasContext.fillRect(0, 0, 600, 200);

        const blob = await canvasToBlob(canvas, 'image/jpeg');
        this.$emit('input', blob);
      }
    },
    async applyImage() {
      const pica = new Pica();
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        const resultCanvas = this.$refs.bannerCanvas;
        try {
          this.processing = true;
          await pica.resize(canvas, resultCanvas);
          const blob = await canvasToBlob(resultCanvas, 'image/jpeg');
          if (this.croppedImage) {
            URL.revokeObjectURL(this.croppedImage);
          }
          this.croppedImage = URL.createObjectURL(blob);
          this.$emit('input', blob);
          this.showCropperDialog = false;
        } finally {
          this.processing = false;
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.m-banner-image-picker__container {
  position: relative;
  .m-banner-image-picker__open-picker {
    position: absolute;
    top: 82px;
    left: 190px;
    color: white !important;
  }
}

.m-banner-image-picker__cropper {
  height: 512px;
  width: 512px;
  background-color: transparent;
}

.m-banner-image-picker__cropper-container {
  position: relative;
  height: 500px;
}

.m-banner-image-picker__cropper-close {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.m-banner-image-picker__cropper-apply {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  bottom: 0;
}
</style>
