import { RouteName } from '@/router/types';
import { MInvitationDialog, MMatchActionDialog, MRitualEnrollDialog, MUserDetailsDialog } from '@/components/Dialogs';
import { MAppointmentFeedbackDialog } from '@/components/Mentoring/dialogs';
import { AppointmentFeedback } from '@mentessa/types';
// import Community from '@/views/Community/Community.vue';

const communityRoutes = [
  {
    path: 'sessions',
    name: RouteName.CommunitySessions,
    props: {
      sessions: true,
    },
  },
  {
    path: 'user/:userId',
    name: RouteName.CommunityUserDetails,
    component: MUserDetailsDialog,
    props: (route) => ({
      userId: route.params['userId'] != null ? Number(route.params['userId']) : undefined,
    }),
  },
  {
    path: 'enroll',
    name: RouteName.CommunityEnroll,
    component: MRitualEnrollDialog,
    props: (route) => ({
      ritualId: route.query['ritualId'] != null ? Number(route.query['ritualId']) : undefined,
    }),
  },
  {
    path: 'matches/:matchId',
    name: RouteName.CommunityMatchAction,
    component: MMatchActionDialog,
    props: (route) => ({
      matchId: route.params['matchId'] != null ? Number(route.params['matchId']) : undefined,
      action: route.query['action'],
      to: { name: RouteName.Community },
      value: true,
    }),
  },
  {
    path: 'invites/:inviteId',
    name: RouteName.CommunityInvite,
    component: MInvitationDialog,
    props: (route) => ({
      inviteId: route.params['inviteId'],
    }),
  },
  {
    path: 'appointments/:appointmentId',
    name: RouteName.CommunityAppointmentFeedback,
    component: MAppointmentFeedbackDialog,
    beforeEnter: (to, from, next) => {
      if (!Object.values(AppointmentFeedback).includes(to.query.feedback as AppointmentFeedback)) {
        return next({
          name: RouteName.Community,
          params: to.params,
          query: { ...to.query, feedback: undefined },
        });
      }
      next();
    },
    props: (route) => ({
      appointmentId: route.params['appointmentId'] != null ? Number(route.params['appointmentId']) : undefined,
      feedback: route.query.feedback,
    }),
  },
];

export { communityRoutes };
export default communityRoutes;
