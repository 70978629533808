"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoomAccessLevel = exports.RoomType = void 0;
var RoomType;
(function (RoomType) {
    RoomType["Direct"] = "direct";
    RoomType["Group"] = "group";
})(RoomType = exports.RoomType || (exports.RoomType = {}));
var RoomAccessLevel;
(function (RoomAccessLevel) {
    RoomAccessLevel["Read"] = "read";
    RoomAccessLevel["Write"] = "write";
    RoomAccessLevel["Admin"] = "admin";
})(RoomAccessLevel = exports.RoomAccessLevel || (exports.RoomAccessLevel = {}));
