"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RitualUserStatus = void 0;
var RitualUserStatus;
(function (RitualUserStatus) {
    RitualUserStatus["Invited"] = "invited";
    RitualUserStatus["Enrollment"] = "enrollment";
    RitualUserStatus["Abandoned"] = "abandoned";
    RitualUserStatus["Available"] = "available";
    RitualUserStatus["Busy"] = "busy";
})(RitualUserStatus = exports.RitualUserStatus || (exports.RitualUserStatus = {}));
