"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmailType = void 0;
var EmailType;
(function (EmailType) {
    EmailType["DirectMessage"] = "direct_message";
    EmailType["MentoringMessage"] = "mentoring_message";
    EmailType["AutomaticMatch"] = "auto_match";
    EmailType["ManualMatch"] = "manual_match";
    EmailType["EventMatch"] = "event_match";
    EmailType["NewAppointmentOrganizer"] = "new_appointment_organizer";
    EmailType["NewAppointmentTarget"] = "new_appointment_target";
    EmailType["AppointmentCanceled"] = "appointment_canceled";
    EmailType["AppointmentReminder"] = "appointment_reminder";
    EmailType["AppointmentReminderHour"] = "appointment_reminder_hour";
    EmailType["AppointmentAcceptedHappyHour"] = "appointment_accepted_happy_hour";
    EmailType["InvitationMember"] = "invitation_member";
    EmailType["InvitationOwner"] = "invitation_owner";
    EmailType["VerifyEmail"] = "verify_email";
    EmailType["ResetPassword"] = "reset_password";
    EmailType["LinkIdp"] = "link_idp";
    EmailType["InviteReminder"] = "invite_reminder";
    EmailType["FillProfileReminder"] = "fill_profile_reminder";
    EmailType["Welcome"] = "welcome";
    EmailType["NewVideo"] = "new_video";
    EmailType["NewVideos"] = "new_videos";
    EmailType["NewNewsAndVideos"] = "new_news_and_videos";
    EmailType["NewNews"] = "new_news";
    EmailType["RitualEnroll"] = "ritual_enroll";
    EmailType["RitualReminderEnroll"] = "ritual_reminder_enroll";
    EmailType["RitualEnrollAccepted"] = "ritual_enroll_accepted";
    EmailType["SkillsMatchingFirstMatch"] = "skills_matching_first_match";
    EmailType["SkillsMatchingSecondMatch"] = "skills_matching_second_match";
    EmailType["SkillsMatchingNoMatch"] = "skills_matching_no_match";
    EmailType["SkillsMatchingAccepted"] = "skills_matching_accepted";
    EmailType["MentoringReminderSetAppointment"] = "mentoring_reminder_set_appointment";
    EmailType["MentoringReminderSetGoals"] = "mentoring_reminder_set_goals";
    EmailType["MentoringGoalCreated"] = "mentoring_goal_created";
    EmailType["MentoringAppointmentCreated"] = "mentoring_appointment_created";
    EmailType["MentoringAppointmentCanceled"] = "mentoring_appointment_canceled";
    EmailType["AppointmentFeedback"] = "appointment_feedback";
    EmailType["MonthlyFeedback"] = "monthly_feedback";
    EmailType["MonthlyFeedbackReminder"] = "monthly_feedback_reminder";
    EmailType["MatchingRequest"] = "matching_request";
    EmailType["MatchingRequestSent"] = "matching_request_sent";
    EmailType["MatchingRequestReminder"] = "matching_request_reminder";
    EmailType["MatchingAccepted"] = "matching_accepted";
    EmailType["MentoringSessionClosed"] = "mentoring_session_closed";
    EmailType["ManagedUserEnrollConfirmation"] = "managed_user_enroll_confirmation";
    EmailType["CommunitySessionCreated"] = "community_session_created";
})(EmailType = exports.EmailType || (exports.EmailType = {}));
