"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AcceptanceRule = exports.AssetIcon = exports.RitualState = exports.RitualType = void 0;
var RitualType;
(function (RitualType) {
    RitualType["Custom"] = "custom";
    RitualType["HappyHour"] = "happy_hour";
    RitualType["EventMatching"] = "event_matching";
    RitualType["SkillsMatching"] = "skills_matching";
})(RitualType = exports.RitualType || (exports.RitualType = {}));
var RitualState;
(function (RitualState) {
    RitualState["Disabled"] = "disabled";
    RitualState["Configure"] = "configure";
    RitualState["Progress"] = "progress";
    RitualState["Finished"] = "finished";
    RitualState["Failed"] = "failed";
    RitualState["Draft"] = "draft";
})(RitualState = exports.RitualState || (exports.RitualState = {}));
var AssetIcon;
(function (AssetIcon) {
    AssetIcon["Cup"] = "cup";
    AssetIcon["Diamond"] = "diamond";
    AssetIcon["Hands"] = "hands";
    AssetIcon["Brain"] = "brain";
    AssetIcon["Briefcase"] = "briefcase";
    AssetIcon["Buildings"] = "buildings";
    AssetIcon["ChequeredFlag"] = "chequered-flag";
    AssetIcon["Diversity"] = "diversity";
    AssetIcon["OpenEmail"] = "email-open";
    AssetIcon["Globe"] = "globe";
    AssetIcon["HammerAndWrench"] = "hammer-and-wrench";
    AssetIcon["Launch"] = "launch";
    AssetIcon["Target"] = "target";
    AssetIcon["WorldMap"] = "world-map";
    AssetIcon["Unlocked"] = "unlocked";
    AssetIcon["MapPin"] = "map-pin";
    AssetIcon["LightBulb"] = "light-bulb";
    AssetIcon["Ledger"] = "ledger";
    AssetIcon["Custom"] = "custom";
})(AssetIcon = exports.AssetIcon || (exports.AssetIcon = {}));
var AcceptanceRule;
(function (AcceptanceRule) {
    AcceptanceRule["Mentor"] = "mentor";
    AcceptanceRule["Mentee"] = "mentee";
    AcceptanceRule["All"] = "all";
    AcceptanceRule["None"] = "none";
})(AcceptanceRule = exports.AcceptanceRule || (exports.AcceptanceRule = {}));
