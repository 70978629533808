<template>
  <div>
    <v-tabs v-model="tab" class="m-sessions-tabs mb-6" background-color="background">
      <v-tab key="my-sessions">
        <h1>{{ $t('session.my_session_title') }}</h1>
      </v-tab>
      <v-tab key="subscribed-sessions">
        <h1>{{ $t('session.subscribed_sessions') }}</h1>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <m-sessions-list :appointments="appointmetsForMe" :groupSessions="mySessions" />
      </v-tab-item>
      <v-tab-item>
        <m-sessions-list :appointments="myAppointments" :groupSessions="subscribedSessions" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import { IAppointment, ICommunitySession, IUser, TenantEntity } from '@mentessa/types';
import { MSessionsList } from '@/components/MSessionsList';
import { GetBookedSessions, MatchingState } from '@/store/matching';
import { UsersState } from '@/store/users';
import { TenantState } from '@/store/tenant';
import { CommunitySessionsState, LoadCommunitySessionsForUser } from '@/store/communitySessions';

export default Vue.extend({
  name: 'm-sessions',
  components: { MSessionsList },
  computed: {
    ...mapState<MatchingState>('matching', {
      appointments: (state: MatchingState): IAppointment[] => state.appointments.items,
    }),
    ...mapState<CommunitySessionsState>('communitySessions', {
      groupSessions: (state: CommunitySessionsState): ICommunitySession[] => state.sessionsCreatedByUser,
    }),
    ...mapState<UsersState>('users', {
      me: (state: UsersState): IUser => state.me,
    }),
    ...mapState<TenantState>('tenant', {
      tenant: (state: TenantState): TenantEntity => state.tenant,
    }),
    myAppointments() {
      return this.appointments.filter((appointment: IAppointment) => {
        return appointment.organizerId === this.me.id;
      });
    },
    appointmetsForMe() {
      return this.appointments.filter((appointment: IAppointment) => {
        return appointment.targetId === this.me.id;
      });
    },
    mySessions() {
      return this.groupSessions.filter((session: ICommunitySession) => {
        return session.createdById === this.me.id;
      });
    },
    subscribedSessions() {
      return this.groupSessions.filter((session: ICommunitySession) => {
        return session.createdById !== this.me.id;
      });
    },
  },
  data: () => ({
    tab: null,
  }),
  methods: {
    async fetchAppointments() {
      await this.$store.dispatch(new GetBookedSessions());
    },
    async fetchGroupSessions() {
      await this.$store.dispatch(new LoadCommunitySessionsForUser(this.tenant, this.me));
    },
  },
  async mounted() {
    await this.fetchAppointments();
    await this.fetchGroupSessions();
  },
});
</script>
<style lang="scss" scoped>
.m-sessions-tabs:deep(.v-tab .v-tab--active) {
  color: var(--v-text-base) !important;
}
</style>
