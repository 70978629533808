"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TenantState = exports.FieldType = void 0;
var FieldType;
(function (FieldType) {
    FieldType["SelectOne"] = "select_one";
    FieldType["SelectMultiple"] = "select_multiple";
    FieldType["ShortText"] = "short_text";
    FieldType["LongText"] = "long_text";
    FieldType["URL"] = "url";
    FieldType["Free"] = "free";
    FieldType["Location"] = "location";
})(FieldType = exports.FieldType || (exports.FieldType = {}));
var TenantState;
(function (TenantState) {
    TenantState["Suspended"] = "suspended";
    TenantState["Active"] = "active";
})(TenantState = exports.TenantState || (exports.TenantState = {}));
