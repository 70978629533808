<template>
  <div></div>
</template>
<script>
import Vue from 'vue';
import Confetti from 'vue-confetti/src/confetti.js';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'm-confetti',
  props: {
    startConfetti: { type: Boolean, default: false },
    duration: { type: Number, default: 5000 },
  },
  data: () => ({
    confetti: new Confetti(),
  }),
  computed: {
    ...mapGetters('tenant', {
      getColorPalette: 'getColorPalette',
    }),
  },
  watch: {
    startConfetti(shouldStart) {
      if (shouldStart) {
        this.confetti.start({
          colors: this.getColorPalette,
          particles: [{ type: 'rect', size: 15 }],
        });
        setTimeout(() => {
          this.confetti.stop();
        }, this.duration);
      }
    },
  },
});
</script>
<style>
#confetti-canvas {
  z-index: 1000;
}
</style>
