import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { CommunitySessionsState, CommunitySessionsActions, CommunitySessionsMutations } from './types';
import { api } from '../api';
import { getIdOrCurrent } from '@/utils/api';
import i18n from '@/plugins/i18n';
import { ShowErrorNotification } from '../notifications';

export const actions: ActionTree<CommunitySessionsState, RootState> = {
  async [CommunitySessionsActions.LoadUpcomingCommunintySessionPage]({ dispatch }, { tenantRef, page }) {
    try {
      const response = await api.get(`tenants/${getIdOrCurrent(tenantRef)}/community-sessions/upcoming`, {
        params: { page: page },
      });
      return response.data;
    } catch (e) {
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [CommunitySessionsActions.LoadCommunintySession]({ dispatch }, { tenantRef, sessionId }) {
    try {
      const response = await api.get(`tenants/${getIdOrCurrent(tenantRef)}/community-sessions/${sessionId}`);
      return response.data;
    } catch (e) {
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [CommunitySessionsActions.SaveCommunitySession]({ commit, dispatch }, { dto, tenantRef }) {
    try {
      const response = await api.postForm(`tenants/${getIdOrCurrent(tenantRef)}/community-sessions`, dto);
      if (response?.data?.id) {
        commit(CommunitySessionsMutations.SetNewCommunitySessionId, response.data.id);
      }
    } catch (e) {
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [CommunitySessionsActions.EditCommunitySession]({ dispatch }, { id, dto, tenantRef }) {
    try {
      const response = await api.patchForm(`tenants/${getIdOrCurrent(tenantRef)}/community-sessions/${id}`, dto);
      if (response?.data?.id) {
        return response.data;
      }
    } catch (e) {
      console.log(e);
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [CommunitySessionsActions.LoadCommunitySessionsForUser]({ commit, dispatch }, { tenantRef, userRef }) {
    try {
      const response = await api.get(`tenants/${getIdOrCurrent(tenantRef)}/community-sessions/user/${userRef.id}`);
      if (response?.data) {
        commit(CommunitySessionsMutations.SetCommunitySessionForUser, response.data);
      }
    } catch (e) {
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [CommunitySessionsActions.SubscribeToCommunitySession]({ dispatch }, { tenantRef, sessionId }) {
    try {
      const response = await api.post(`tenants/${getIdOrCurrent(tenantRef)}/community-sessions/subscribe/${sessionId}`);
      return response.data;
    } catch (e) {
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  async [CommunitySessionsActions.UnsubscribeToCommunitySession]({ dispatch }, { tenantRef, sessionId }) {
    try {
      const response = await api.post(
        `tenants/${getIdOrCurrent(tenantRef)}/community-sessions/unsubscribe/${sessionId}`,
      );
      return response.data;
    } catch (e) {
      await dispatch(new ShowErrorNotification(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
      return false;
    }
  },

  [CommunitySessionsActions.ChangeCreateCommunitySessionVisible]({ commit }, { visible }) {
    commit(CommunitySessionsMutations.SetCreateCommunitySessionVisible, visible);
  },

  [CommunitySessionsActions.CommunitySessionCreatedSuccessfully]({ commit }, { id }) {
    commit(CommunitySessionsMutations.SetNewCommunitySessionId, id);
  },

  [CommunitySessionsActions.CloseCommunitySessionCreatedSuccessfull]({ commit }) {
    commit(CommunitySessionsMutations.SetNewCommunitySessionId, undefined);
    commit(CommunitySessionsMutations.SetCreateCommunitySessionVisible, false);
  },
};
